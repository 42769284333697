@mixin create-recommended-product-icon {
  .recommended-product {
    position: absolute;
    z-index: 1;
    transition: .3s;
    border-style: solid;
    width: 0px;
    height: 0px;
    line-height: 0px;
    border-color: $recommended-product-icon-container-color transparent transparent;
    border-width: 40px 40px 0px 0px;
  }

  .recommended-product span {
    transform: rotate(30deg);
    color: $recommended-product-icon-color;
    position: absolute;
    top: -38px;
    display: inline-block;
    left: 3px;
    font-size: 20px;
  }
}