@use "sass:math";

.sightseeing__search_results {
    @include clearfix;
  .search__result {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    &_wrapper {
        @include clearfix;
        .alert__message {
            margin-left: 0.9375rem;
            margin-right: 0.9375rem;
        }
    }
    &_row{
	    position: relative;
		text-transform: none;
		@include create-recommended-product-icon();
		text-align: left;
    }
    
    &_card {
      box-shadow: $row-shadow-color;
      @include clearfix;
    }
    &_sightseeing,
    &_price {
      @include make-one-fifth;
    }
    
    &_tax-header{
    @include clearfix;
	margin-top: -22px;
	margin-right: 1.9rem;
	padding: 0.25rem;
    color: $dark-green;
    background-color: $saffron;
    box-shadow: $row-shadow-color;
    margin-bottom: 1rem;
    	.tax-message{
    		font-size: 0.9rem;
    		padding-left: 0.25rem;
    	}
    }
    
	&_header{
		text-transform:uppercase;
		color:$brand-color-2;
		border-bottom: 1px solid darken($brand-color-4,20);
		padding-top: $base-padding*0.2;
		padding-bottom: $base-padding*0.2;
		padding-left: $base-padding*0.2;
	    padding-right: $base-padding*0.2;		
	    margin-bottom: $base-margin*0.2;
	    margin-left: -$base-margin*0.5;
	    margin-right: -15px;
	    
	    .selected-date{
	    	float:right;
	    	color:$brand-color-2;
	    }
	   
	    .delete-sight-seeing{
	    	margin-right:15px;
	    	float:right;
	    	color:$brand-color-2;
	    	cursor: pointer;
	    }
	}
	&_detail {
      @include make-three-fifth;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: $brand-color-3;
      min-height: calc(#{$base-padding}/3 + 80px);
    }
    &_description,
    &_extra-desc {
      padding-top: math.div($base-padding, 3);
      @include clearfix;
      width: 100%;
    }
    &_description {
      padding-bottom: math.div($base-padding, 3);
    }
    &_sightseeing{
   	  padding:0;
    }
    &-sightseeing,&-description {
      @include make-two-fifth;
      margin-bottom: 15px;
      img {
	      width: 100%;
	      max-height: 80px;
	      cursor: pointer;
	      padding:1px;
    	}
    }
   &_price {
 		padding-left:10px;
        padding-right:10px;
        padding-top:10px;
		&-marhaba-product {
				text-align: center;
		}
    }   
 
   &_trip {
    	@include make-three-fifth;
    	padding-left: 0;
    	padding-right: 0;
    	border-right: 1px solid;
        border-left: 1px solid;
        border-color: $row-flight-border-color;
    }
    &_from,
    &_to,
    &_duration {
    	@include make-one-third;
    }
  }
  
.result {
    &__fname {
      text-transform: capitalize;
    }
    &__sightseeing{
    &_heading{
    	font-size: $font-size-h3;
        color: $row-time-color;
        font-weight: 400;
    }
    &_data{
    &-header{
	    margin-right:0.313rem;
	    text-transform: capitalize;
	    font-size:0.688rem;
    }
  }
}
    
&__description{
    padding-top: .9375rem;
    &_heading{
    	font-size: $font-size-h3;
        color: $row-time-color;
        font-weight: 400;
    }
    &_data{
	    float: left; 
	    width: 45%;
	    text-align: left;
	    &-header{
		    margin-right:5px;
	        text-transform: capitalize;
		    font-size:0.688rem;
	    }
    }
    &_cangellation-policy{
	    color: $row-time-color;
    }
}

&__price-pax{
	&_data-header{
	    display: inline-block;
	    font-weight: bold;
	    color: $brand-color-2;
	}
}

 
    
    
&__price {
    height: 76%;
    padding-top: .9375rem;
      &_amount,&_amount-with-adhoc {
        font-size: $font-size-h3;
        font-family: $font-family-number;
		color: $brand-color-1;
      }
      .options-adhoc{
		text-align: center;
	  }
      &_amount-with-adhoc{
       	color : $brand-color-14;
	
      }
      &_adhoc-amount{
   	  	display: inline-block;
   	  	width: 100%;
    	text-align: center;
      }
    }
    &__status {
    	position: absolute;
    	bottom: 0px;
    	width: 18%;
    	text-align: center;
    	
    	text-transform: uppercase;
    }
    &__price-status {
    	width: 100%;
    	text-transform: capitalize;
    }
    &__extra-details{
	    @include make-four-fifth;
	    text-transform: capitalize;
    }
    &__sightseeing_highlights,&__sightseeing_options{
	    @include make-one-third;
	    min-height: 25px;
	    text-align: center;
	    width:20%;
	    font-size:.625rem;
	    text-transform:uppercase;
		align-items: center;
		display: flex;
		&-link {
				color: $brand-color-2;
				cursor: pointer;
    	   		font-weight: 600;
    	   		text-align: center;
    	   		margin: 0 auto;
		}
	    .highlights{
	    	padding: .3125rem 0;
    		background-color: $brand-color-3;
    		width: 100%;
    		&:hover,
     	    &:active,
      		&:focus {
                cursor: pointer;
    	   		text-transform: uppercase;
    	   		font-size: .625rem;
    	   		font-weight: 600;
           		color: $row-flight-details-button-state;
           		text-decoration: none;
      		}
      		&:hover {
      	   		background-color:$brand-color-3;
           		text-decoration: underline;
        	}
	    }
	    > a{
		    color:$brand-color-2;
		    cursor: pointer;
	    }
    }
 
	&__sightseeing_extra_supplier-name-cancellation-policy{
		border-left: 1px solid $brand-color-3;
	    border-right: 1px solid $brand-color-3;
		width: 60%;
        display: table;
        float: left;
	}

    &__sightseeing_extra_details,&__sightseeing_extra_cancellation-policy,&__sightseeing_extra_sightseeing-details{
	    @include make-one-third;
	    min-height: 1.563rem;
	    background-color: $row-footer-bgcolor;
	    text-align: left;
	    font-size:.625rem;
		width:60%;
		padding-right: .625rem;
		color:$brand-color-2;
		display: flex;
        align-items: center;
		    > a{
		    	text-transform:uppercase;
	    	    float: right;
	    	    color:$brand-color-2;
    			position: relative;
    			padding: .3125rem 0;
  				width: 30%;
  				margin-right: 2%;
  				text-align: center;
    			background-color: $brand-color-3;
    			&:hover,
     			&:active,
      		    &:focus {
           			cursor: pointer;
    	   			text-transform: uppercase;
    	   			font-size: .625rem;
    	   			font-weight: 600;
           			color: $row-flight-details-button-state;
           			text-decoration: none;
      		 	}
      			&:hover {
      	   			background-color:$brand-color-3;
           			text-decoration: underline;
	    	}
	    }
	    }
    &__sightseeing_extra_cancellation-policy{
	    width:45%;
		cursor: pointer;
    }
    &__sightseeing_extra_sightseeing-details{
	   width:25%;
    }
    &__sightseeing_highlights-active,&__sightseeing_options-active{
	    @include make-one-third;
	    min-height: 25px;
	    background-color: $brand-color-3;
	    padding-top:5px;
	    margin-left:-5px;
	    text-align:center;
    }
    &__sightseeing,
    &__marhaba,
    &__sightseeing-rfq {
	    text-align: center;
	    max-height: 100px;
	    img {
	      	width: 100%;
	      	height: 122px;
	      	padding:0;
	  	}
	}
	&__sightseeing-rfq {
		img {
			width: 100%;
			height: 122px;
			padding: 0;
		}
	}
		&__marhaba {
			img {
				height: 100px;
			}
		}
    &__book, &__select {
      @include make-one-fifth;
	  float: right;
      padding-left: math.div($base-padding, 3);
      padding-right: math.div($base-padding, 3);
      &_button, &_button-active {
          @include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
          @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
          width: 100%;
          font-weight: 700;
          text-transform: uppercase;
      }
    }
    &__select {
    	border-color: $row-book-border-color;
    	&_button {
      		@include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
    	}
    	&_button-active {
      		@include make-button($brand-color-2, $brand-color-3, $brand-color-2, $brand-color-3);
    	}
  	}
  	&__select-button-triangle {
    	position: relative;
    	margin-left: math.div($base-padding, 6);
    	top: math.div($base-padding, 3);
    	@include make-triangle(bottom, 7px, $light);

    	&-active {
      		position: relative;
      		margin-left: 5px;
      		top: 10px;
      		@include make-triangle(bottom, 7px, $brand-color-2);
    	}
  	}
  }
}

.description {
  &__sightseeing {
    @include make-three-fifth;
    padding-left: 0;
    width:100%;
    border-bottom: 2px solid $brand-color-4;
    padding-bottom: $base-padding*0.05;
    padding-top: $base-padding*0.1;
    &_name,
    &_city {
      max-height: 100px;
      text-align: left;
      font-family: $font-family-sans-serif;
      color: $brand-color-2;
      font-size: 0.865rem;
      font-weight: 400;
      text-transform: uppercase;
    }
    &_name{
		 text-transform: capitalize;
		 
	}
    &_city {
      color: $brand-color-5;
      font-size: 12px;
      font-weight: 600;
    }
    &_star-rating {
      color: $brand-color-2;
      font-size: 1rem;
      font-weight: 600;
    }
    &_desc{
    	-webkit-line-clamp: 3;
    	-webkit-box-orient: vertical;
    	overflow: hidden;
    	text-overflow: ellipsis;
        display: -webkit-box;
        word-wrap: break-word;
        line-height: 1.2;
        max-height: 3.6rem;
        text-align: left;
        padding-top:.3125rem;
        > p{
			margin-bottom: 0;
		 }
    }
	&_message{
		color: $brand-color-1;
	}
  }
}

.sightseeing-result-price-status{
	position: absolute;
  	width: 18%;
  	bottom: 0px;
  	text-align: center;
	&__available,
  	&__unavailable,
	&__price-changed{
		margin: 0 auto;
	    max-width: 150px;
	    color: $orange;
	    text-transform: uppercase;
	}
	&__available{
	    color: $green;
	}
	&__unavailable{
	    color: $brand-color-9;
	}
}

.highlights{
	cursor: pointer;
}

.sightseeing-header-container {
	float: right;
	width: 75%;
	padding-left: 22px;
}