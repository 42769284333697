.sightseeing__search_results {
 	@include search-info;
  .search-details {
    background-color: $md-bgcolor;
    @include create-main-wrapper;
    &_wrapper {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &__row {
      @include clearfix;

      // margin-left: 15px;
      > li {
        @include make-one-fourth;
        padding-left: 0;
        padding-right: 0;
        border-right: 2px solid $md-border-color;
        &:first-child {
          border-left: 2px solid $md-border-color;
        }
        &:nth-child(3) {
          border-right: none;
        }
        &:last-child {
          padding-right: $base-padding*0.5;
          border-right: none;
        }
      }
      &_traveller {
        margin-right: -15px;
      }
    }
    .adult,
    .child,
    .infants {
      &__data {
        display: block;
        &_heading {
          color: $traveller-heading-color;
        }
        &_value {
          color: $traveller-value-color;
        }
      }
    }
    .oneway,
    .return {
      &__data,
       {
        @include clearfix;

        // border-left: 2px solid white;
        padding-left: $base-padding*0.5;
        padding-right: $base-padding*0.5;
        &_icon {
          float: left;

          // background-color: red;
          font-size: 2.25rem;

          // color: white;
          width: 20%;
        }
        &_detail {
          float: left;
          padding: 9px;
          width: 80%;
          .location {
            color: $md-heading-color;
          }
          .departure {
            @include clearfix;
            .departure__heading {
              float: left;
            }
            .departure__date {
              float: left;
            }
          }
        }
      }
    }
    .oneway__data {
      &_icon {
        span.glyphicon.glyphicon-plane {
          transform: rotate(45deg);
          top: 5px;
        }
      }
    }
    .return__data {
      &_icon {
        span.glyphicon.glyphicon-plane {
          transform: rotate(-45deg);
          top: 5px;
        }
      }
    }
    .traveller {
      &__row {
        @include clearfix;
        > li {
          text-align: center;
          width: 33.33%;
          border-left: 2px solid $md-border-color;
          padding-top: 9px;
          padding-bottom: 9px;
          float: left;
          &:last-child {
            border-right: 2px solid $md-border-color;
          }
        }
      }
    }
  }
}