@use "sass:math";

.sightseeing__pax-info {
	@include create-pax-info;
	@include create-main-wrapper;
	@include create-row-card;
	@include create-row-card-header;
	@include create-card-content;
	padding-left: 0.9375rem;

	&_card_header {
		padding-left: 0;
	}
	&_header{
		text-transform: capitalize;
		padding-left:0; 
		border-top: 1px solid $brand-color-2;
	}

	&_row {
		@include clearfix;
		border-top: 1px solid $brand-color-2;

		&:last-child {
			border-bottom: 1px solid $brand-color-2;
		}
	}

	&_save-traveler {
		margin-bottom: 0.9375rem;
	}
}

.view__cart {
	float: right;
	padding: math.div($base-padding, 6);
}

.sightseeing__pax-info_main .content {
	padding-left: 0.9375rem;
}


.note-box {
	padding: 0.4375rem;
}