@import url(https://fonts.googleapis.com/css?family=Raleway:700,500,600|Open+Sans:400,600,700);
@media print {
  .search__result_wrapper {
    display: none;
  }
}
.search-location-checkbox {
  padding-top: 0.2375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.multi-select-dropdown {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  position: relative;
}
.multi-select-dropdown .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
}

.sightseeing__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.sightseeing__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.sightseeing__search-form-container_main:before, .sightseeing__search-form-container_main:after {
  content: " ";
  display: table;
}
.sightseeing__search-form-container_main:after {
  clear: both;
}
.sightseeing__search-form-container_main .content {
  padding-left: 0;
}
.sightseeing__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .sightseeing__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .sightseeing__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.sightseeing__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.sightseeing__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-wrapper:before, .sightseeing__search-wrapper:after {
  content: " ";
  display: table;
}
.sightseeing__search-wrapper:after {
  clear: both;
}
.sightseeing__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.sightseeing__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.sightseeing__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.sightseeing__search-trip-type, .sightseeing__search-location, .sightseeing__search-date, .sightseeing__search-traveller, .sightseeing__search-multicity, .sightseeing__search-nationality, .sightseeing__search-residence-country, .sightseeing__search-currency, .sightseeing__additioal-option, .sightseeing__pnr-search-location, .sightseeing__product-selection, .sightseeing__advance-search, .sightseeing__advance-search-params, .sightseeing__search-supplier, .sightseeing__search-modify-journey {
  margin-bottom: 1rem;
}
.sightseeing__with-baggage_label {
  padding: 0.3125rem;
}
.sightseeing__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__pnr-fields:before, .sightseeing__pnr-fields:after {
  content: " ";
  display: table;
}
.sightseeing__pnr-fields:after {
  clear: both;
}
.sightseeing__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.sightseeing__search-trip-type, .sightseeing__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.sightseeing__search-trip-type:before, .sightseeing__search-trip-type:after, .sightseeing__search-modify-journey:before, .sightseeing__search-modify-journey:after {
  content: " ";
  display: table;
}
.sightseeing__search-trip-type:after, .sightseeing__search-modify-journey:after {
  clear: both;
}
.sightseeing__search-trip-type .radio-inline, .sightseeing__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .sightseeing__search-trip-type .radio-inline, .sightseeing__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.sightseeing__search-trip-type .radio-inline input[disabled], .sightseeing__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.sightseeing__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.sightseeing__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.sightseeing__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-location:before, .sightseeing__search-location:after {
  content: " ";
  display: table;
}
.sightseeing__search-location:after {
  clear: both;
}
.sightseeing__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  width: 48%;
}
.sightseeing__search-location .search-location_exachange-icon {
  width: 3%;
  float: left;
  min-height: 1px;
  padding-top: 1rem;
}
.sightseeing__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.sightseeing__search-date, .sightseeing__hub-surcharge, .sightseeing__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-date:before, .sightseeing__search-date:after, .sightseeing__hub-surcharge:before, .sightseeing__hub-surcharge:after, .sightseeing__search-date_hotel-dates:before, .sightseeing__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.sightseeing__search-date:after, .sightseeing__hub-surcharge:after, .sightseeing__search-date_hotel-dates:after {
  clear: both;
}
.sightseeing__search-date .search-date_select, .sightseeing__search-date_baggage-one-way, .sightseeing__hub-surcharge .search-date_select, .sightseeing__hub-surcharge_baggage-one-way, .sightseeing__search-date_hotel-dates .search-date_select, .sightseeing__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.sightseeing__search-date_baggage-one-way .baggage-label, .sightseeing__hub-surcharge_baggage-one-way .baggage-label, .sightseeing__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.sightseeing__search-date_baggage-round-multicity .baggage-label, .sightseeing__hub-surcharge_baggage-round-multicity .baggage-label, .sightseeing__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.sightseeing__search-date_hotel-dates .search-date_select, .sightseeing__hub-surcharge_hotel-dates .search-date_select, .sightseeing__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.sightseeing__search-date .date-field-with-duration, .sightseeing__hub-surcharge .date-field-with-duration, .sightseeing__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.sightseeing__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.sightseeing__search-date-verticle:before, .sightseeing__search-date-verticle:after {
  content: " ";
  display: table;
}
.sightseeing__search-date-verticle:after {
  clear: both;
}
.sightseeing__search-date-verticle .date-range-picker__from-date, .sightseeing__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.sightseeing__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.sightseeing__hub-surcharge-container:before, .sightseeing__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.sightseeing__hub-surcharge-container:after {
  clear: both;
}
.sightseeing__search-nationality, .sightseeing__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-nationality:before, .sightseeing__search-nationality:after, .sightseeing__search-residence-country:before, .sightseeing__search-residence-country:after {
  content: " ";
  display: table;
}
.sightseeing__search-nationality:after, .sightseeing__search-residence-country:after {
  clear: both;
}
.sightseeing__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-currency:before, .sightseeing__search-currency:after {
  content: " ";
  display: table;
}
.sightseeing__search-currency:after {
  clear: both;
}
.sightseeing__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.sightseeing__search-multicity-row, .sightseeing__search-multicity_hotel-dates, .sightseeing__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-multicity-row:before, .sightseeing__search-multicity-row:after, .sightseeing__search-multicity_hotel-dates:before, .sightseeing__search-multicity_hotel-dates:after, .sightseeing__search-multicity_hotel-search-date:before, .sightseeing__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.sightseeing__search-multicity-row:after, .sightseeing__search-multicity_hotel-dates:after, .sightseeing__search-multicity_hotel-search-date:after {
  clear: both;
}
.sightseeing__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.sightseeing__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.sightseeing__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.sightseeing__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.sightseeing__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.sightseeing__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.sightseeing__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.sightseeing__search-multicity_date .search-date_select {
  width: 100%;
}
.sightseeing__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.sightseeing__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.sightseeing__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.sightseeing__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.sightseeing__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.sightseeing__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.sightseeing__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-traveller:before, .sightseeing__search-traveller:after {
  content: " ";
  display: table;
}
.sightseeing__search-traveller:after {
  clear: both;
}
.sightseeing__search-traveller:before, .sightseeing__search-traveller:after {
  content: " ";
  display: table;
}
.sightseeing__search-traveller:after {
  clear: both;
}
.sightseeing__search-traveller_adult, .sightseeing__search-traveller_child, .sightseeing__search-traveller_infant, .sightseeing__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.sightseeing__search-traveller_adult input, .sightseeing__search-traveller_child input, .sightseeing__search-traveller_infant input, .sightseeing__search-traveller_class input {
  text-align: center;
}
.sightseeing__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.sightseeing__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.sightseeing__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.sightseeing__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.sightseeing__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__advance-search-params:before, .sightseeing__advance-search-params:after {
  content: " ";
  display: table;
}
.sightseeing__advance-search-params:after {
  clear: both;
}
.sightseeing__advance-search-params_stops, .sightseeing__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.sightseeing__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.sightseeing__search-supplier:before, .sightseeing__search-supplier:after {
  content: " ";
  display: table;
}
.sightseeing__search-supplier:after {
  clear: both;
}
.sightseeing__supplier-selection label {
  padding-right: 2rem;
}
.sightseeing__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__additioal-option:before, .sightseeing__additioal-option:after {
  content: " ";
  display: table;
}
.sightseeing__additioal-option:after {
  clear: both;
}
.sightseeing__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.sightseeing__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search-submit:before, .sightseeing__search-submit:after {
  content: " ";
  display: table;
}
.sightseeing__search-submit:after {
  clear: both;
}
.sightseeing__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.sightseeing__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.sightseeing__search-submit_button .submit_button:focus, .sightseeing__search-submit_button .submit_button.focus, .sightseeing__search-submit_button .submit_button:active:focus, .sightseeing__search-submit_button .submit_button:active.focus, .sightseeing__search-submit_button .submit_button.active:focus, .sightseeing__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__search-submit_button .submit_button:hover, .sightseeing__search-submit_button .submit_button:focus, .sightseeing__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.sightseeing__search-submit_button .submit_button:active, .sightseeing__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__search-submit_button .submit_button.disabled, .sightseeing__search-submit_button .submit_button[disabled], fieldset[disabled] .sightseeing__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__search-form_container input[type=text], .sightseeing__search-form_container input[type=number],
.sightseeing__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.sightseeing__search-form_container input[type=text], .sightseeing__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.sightseeing__search-form_container .number-stepper {
  max-width: 250px;
}
.sightseeing__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.sightseeing__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.sightseeing__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.sightseeing__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.sightseeing__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.sightseeing__advertise p {
  margin-bottom: 0;
}
.sightseeing__search-time, .sightseeing__search-date, .sightseeing__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.sightseeing__search-time:before, .sightseeing__search-time:after, .sightseeing__search-date:before, .sightseeing__search-date:after, .sightseeing__input-field:before, .sightseeing__input-field:after {
  content: " ";
  display: table;
}
.sightseeing__search-time:after, .sightseeing__search-date:after, .sightseeing__input-field:after {
  clear: both;
}
.sightseeing__search-time_select, .sightseeing__search-date_select, .sightseeing__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.sightseeing__search-time_half, .sightseeing__search-date_half, .sightseeing__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.sightseeing__search-nav {
  background-color: #087dc2;
}
.sightseeing__search-nav:before, .sightseeing__search-nav:after {
  content: " ";
  display: table;
}
.sightseeing__search-nav:after {
  clear: both;
}
.sightseeing__search-nav_list li {
  float: left;
  background-color: #087dc2;
  border-right: 2px solid;
  border-color: #fff;
}
.sightseeing__search-nav_list li a {
  padding: 0.625rem 0.625rem;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
.sightseeing__search-nav_list li a:hover {
  background-color: #ec2227;
  color: #fff;
  text-decoration: none;
}
.sightseeing__search-nav_list .active {
  background-color: #ec2227;
}
.sightseeing__search-nav_list .active a {
  color: #fff;
}
@media (max-width: 991px) {
  .sightseeing__search-nav_list {
    display: none;
  }
}
.sightseeing__search-form_container_sightseeing-type {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.sightseeing__search-form_container_sightseeing-type:before, .sightseeing__search-form_container_sightseeing-type:after {
  content: " ";
  display: table;
}
.sightseeing__search-form_container_sightseeing-type:after {
  clear: both;
}
.sightseeing__search-form_container_sightseeing-type .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
}
.sightseeing__search-form_container .search-vehicle-type {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.sightseeing__search-form_container .search-vehicle-type:before, .sightseeing__search-form_container .search-vehicle-type:after {
  content: " ";
  display: table;
}
.sightseeing__search-form_container .search-vehicle-type:after {
  clear: both;
}
.sightseeing__search-form_container .search-vehicle-type_SIC, .sightseeing__search-form_container .search-vehicle-type_private-vehicle {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.33333%;
}
.sightseeing__search-traveller-selector {
  margin-left: -15px;
  margin-right: -15px;
}

.transfer__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.transfer__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.transfer__search-form-container_main:before, .transfer__search-form-container_main:after {
  content: " ";
  display: table;
}
.transfer__search-form-container_main:after {
  clear: both;
}
.transfer__search-form-container_main .content {
  padding-left: 0;
}
.transfer__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .transfer__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .transfer__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.transfer__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.transfer__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-wrapper:before, .transfer__search-wrapper:after {
  content: " ";
  display: table;
}
.transfer__search-wrapper:after {
  clear: both;
}
.transfer__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.transfer__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.transfer__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.transfer__search-trip-type, .transfer__search-location, .transfer__search-date, .transfer__search-traveller, .transfer__search-multicity, .transfer__search-nationality, .transfer__search-residence-country, .transfer__search-currency, .transfer__additioal-option, .transfer__pnr-search-location, .transfer__product-selection, .transfer__advance-search, .transfer__advance-search-params, .transfer__search-supplier, .transfer__search-modify-journey {
  margin-bottom: 1rem;
}
.transfer__with-baggage_label {
  padding: 0.3125rem;
}
.transfer__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__pnr-fields:before, .transfer__pnr-fields:after {
  content: " ";
  display: table;
}
.transfer__pnr-fields:after {
  clear: both;
}
.transfer__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.transfer__search-trip-type, .transfer__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.transfer__search-trip-type:before, .transfer__search-trip-type:after, .transfer__search-modify-journey:before, .transfer__search-modify-journey:after {
  content: " ";
  display: table;
}
.transfer__search-trip-type:after, .transfer__search-modify-journey:after {
  clear: both;
}
.transfer__search-trip-type .radio-inline, .transfer__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .transfer__search-trip-type .radio-inline, .transfer__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.transfer__search-trip-type .radio-inline input[disabled], .transfer__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.transfer__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.transfer__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.transfer__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-location:before, .transfer__search-location:after {
  content: " ";
  display: table;
}
.transfer__search-location:after {
  clear: both;
}
.transfer__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  width: 48%;
}
.transfer__search-location .search-location_exachange-icon {
  width: 3%;
  float: left;
  min-height: 1px;
  padding-top: 1rem;
}
.transfer__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.transfer__search-date, .transfer__hub-surcharge, .transfer__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-date:before, .transfer__search-date:after, .transfer__hub-surcharge:before, .transfer__hub-surcharge:after, .transfer__search-date_hotel-dates:before, .transfer__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.transfer__search-date:after, .transfer__hub-surcharge:after, .transfer__search-date_hotel-dates:after {
  clear: both;
}
.transfer__search-date .search-date_select, .transfer__search-date_baggage-one-way, .transfer__hub-surcharge .search-date_select, .transfer__hub-surcharge_baggage-one-way, .transfer__search-date_hotel-dates .search-date_select, .transfer__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.transfer__search-date_baggage-one-way .baggage-label, .transfer__hub-surcharge_baggage-one-way .baggage-label, .transfer__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.transfer__search-date_baggage-round-multicity .baggage-label, .transfer__hub-surcharge_baggage-round-multicity .baggage-label, .transfer__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.transfer__search-date_hotel-dates .search-date_select, .transfer__hub-surcharge_hotel-dates .search-date_select, .transfer__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.transfer__search-date .date-field-with-duration, .transfer__hub-surcharge .date-field-with-duration, .transfer__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.transfer__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.transfer__search-date-verticle:before, .transfer__search-date-verticle:after {
  content: " ";
  display: table;
}
.transfer__search-date-verticle:after {
  clear: both;
}
.transfer__search-date-verticle .date-range-picker__from-date, .transfer__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.transfer__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.transfer__hub-surcharge-container:before, .transfer__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.transfer__hub-surcharge-container:after {
  clear: both;
}
.transfer__search-nationality, .transfer__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-nationality:before, .transfer__search-nationality:after, .transfer__search-residence-country:before, .transfer__search-residence-country:after {
  content: " ";
  display: table;
}
.transfer__search-nationality:after, .transfer__search-residence-country:after {
  clear: both;
}
.transfer__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-currency:before, .transfer__search-currency:after {
  content: " ";
  display: table;
}
.transfer__search-currency:after {
  clear: both;
}
.transfer__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.transfer__search-multicity-row, .transfer__search-multicity_hotel-dates, .transfer__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-multicity-row:before, .transfer__search-multicity-row:after, .transfer__search-multicity_hotel-dates:before, .transfer__search-multicity_hotel-dates:after, .transfer__search-multicity_hotel-search-date:before, .transfer__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.transfer__search-multicity-row:after, .transfer__search-multicity_hotel-dates:after, .transfer__search-multicity_hotel-search-date:after {
  clear: both;
}
.transfer__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.transfer__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.transfer__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.transfer__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.transfer__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.transfer__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.transfer__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.transfer__search-multicity_date .search-date_select {
  width: 100%;
}
.transfer__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.transfer__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.transfer__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.transfer__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.transfer__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.transfer__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.transfer__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-traveller:before, .transfer__search-traveller:after {
  content: " ";
  display: table;
}
.transfer__search-traveller:after {
  clear: both;
}
.transfer__search-traveller:before, .transfer__search-traveller:after {
  content: " ";
  display: table;
}
.transfer__search-traveller:after {
  clear: both;
}
.transfer__search-traveller_adult, .transfer__search-traveller_child, .transfer__search-traveller_infant, .transfer__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.transfer__search-traveller_adult input, .transfer__search-traveller_child input, .transfer__search-traveller_infant input, .transfer__search-traveller_class input {
  text-align: center;
}
.transfer__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.transfer__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.transfer__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.transfer__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.transfer__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__advance-search-params:before, .transfer__advance-search-params:after {
  content: " ";
  display: table;
}
.transfer__advance-search-params:after {
  clear: both;
}
.transfer__advance-search-params_stops, .transfer__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.transfer__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.transfer__search-supplier:before, .transfer__search-supplier:after {
  content: " ";
  display: table;
}
.transfer__search-supplier:after {
  clear: both;
}
.transfer__supplier-selection label {
  padding-right: 2rem;
}
.transfer__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__additioal-option:before, .transfer__additioal-option:after {
  content: " ";
  display: table;
}
.transfer__additioal-option:after {
  clear: both;
}
.transfer__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.transfer__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-submit:before, .transfer__search-submit:after {
  content: " ";
  display: table;
}
.transfer__search-submit:after {
  clear: both;
}
.transfer__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.transfer__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.transfer__search-submit_button .submit_button:focus, .transfer__search-submit_button .submit_button.focus, .transfer__search-submit_button .submit_button:active:focus, .transfer__search-submit_button .submit_button:active.focus, .transfer__search-submit_button .submit_button.active:focus, .transfer__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.transfer__search-submit_button .submit_button:hover, .transfer__search-submit_button .submit_button:focus, .transfer__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.transfer__search-submit_button .submit_button:active, .transfer__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.transfer__search-submit_button .submit_button.disabled, .transfer__search-submit_button .submit_button[disabled], fieldset[disabled] .transfer__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.transfer__search-form_container input[type=text], .transfer__search-form_container input[type=number],
.transfer__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.transfer__search-form_container input[type=text], .transfer__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.transfer__search-form_container .number-stepper {
  max-width: 250px;
}
.transfer__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.transfer__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.transfer__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.transfer__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.transfer__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.transfer__advertise p {
  margin-bottom: 0;
}
.transfer__search-time, .transfer__search-date, .transfer__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.transfer__search-time:before, .transfer__search-time:after, .transfer__search-date:before, .transfer__search-date:after, .transfer__input-field:before, .transfer__input-field:after {
  content: " ";
  display: table;
}
.transfer__search-time:after, .transfer__search-date:after, .transfer__input-field:after {
  clear: both;
}
.transfer__search-time_select, .transfer__search-date_select, .transfer__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.transfer__search-time_half, .transfer__search-date_half, .transfer__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.transfer__search-nav {
  background-color: #087dc2;
}
.transfer__search-nav:before, .transfer__search-nav:after {
  content: " ";
  display: table;
}
.transfer__search-nav:after {
  clear: both;
}
.transfer__search-nav_list li {
  float: left;
  background-color: #087dc2;
  border-right: 2px solid;
  border-color: #fff;
}
.transfer__search-nav_list li a {
  padding: 0.625rem 0.625rem;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
.transfer__search-nav_list li a:hover {
  background-color: #ec2227;
  color: #fff;
  text-decoration: none;
}
.transfer__search-nav_list .active {
  background-color: #ec2227;
}
.transfer__search-nav_list .active a {
  color: #fff;
}
@media (max-width: 991px) {
  .transfer__search-nav_list {
    display: none;
  }
}
.transfer__search-vehicle-type {
  margin-left: 0;
  margin-right: 0;
}
.transfer__search-pickupdropoff-container, .transfer__search-vehicle-type {
  margin-bottom: 1rem;
}
.transfer__search-vehicle-type {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.transfer__search-vehicle-type:before, .transfer__search-vehicle-type:after {
  content: " ";
  display: table;
}
.transfer__search-vehicle-type:after {
  clear: both;
}
.transfer__search-vehicle-type .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
}
.transfer__search-pickupdropoff-container {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-pickupdropoff-container:before, .transfer__search-pickupdropoff-container:after {
  content: " ";
  display: table;
}
.transfer__search-pickupdropoff-container:after {
  clear: both;
}
.transfer__search-pickupdropoff-container_pickup-wrapper, .transfer__search-pickupdropoff-container_dropoff-wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.transfer__search-airport-select-container {
  margin-left: -15px;
  margin-right: -15px;
}
.transfer__search-airport-select-container:before, .transfer__search-airport-select-container:after {
  content: " ";
  display: table;
}
.transfer__search-airport-select-container:after {
  clear: both;
}
.transfer__search-airport-select-container_pickup-point-airport, .transfer__search-airport-select-container_dropoff-point-airport {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.transfer__search-time, .transfer__search-date {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.transfer__search-time:before, .transfer__search-time:after, .transfer__search-date:before, .transfer__search-date:after {
  content: " ";
  display: table;
}
.transfer__search-time:after, .transfer__search-date:after {
  clear: both;
}
.transfer__search-time_select, .transfer__search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}

.pickup-wrapper__pickup-point_title, .pickup-wrapper__dropoff-point_title, .dropoff-wrapper__pickup-point_title, .dropoff-wrapper__dropoff-point_title {
  color: #087dc2;
  font-size: 0.875rem;
}
.pickup-wrapper__pickup-point-airport, .pickup-wrapper__dropoff-point-airport, .dropoff-wrapper__pickup-point-airport, .dropoff-wrapper__dropoff-point-airport {
  margin-top: 1rem;
}
.pickup-wrapper__pickup-point-airport_title, .pickup-wrapper__dropoff-point-airport_title, .dropoff-wrapper__pickup-point-airport_title, .dropoff-wrapper__dropoff-point-airport_title {
  color: #087dc2;
  font-size: 0.875rem;
}

.sightseeing__search_results:before, .sightseeing__search_results:after {
  content: " ";
  display: table;
}
.sightseeing__search_results:after {
  clear: both;
}
.sightseeing__search_results .search__result_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.sightseeing__search_results .search__result_main:before, .sightseeing__search_results .search__result_main:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_main:after {
  clear: both;
}
.sightseeing__search_results .search__result_main .content {
  padding-left: 0;
}
.sightseeing__search_results .search__result_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .sightseeing__search_results .search__result_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .sightseeing__search_results .search__result_wrapper {
    max-width: 1400px;
  }
}
.sightseeing__search_results .search__result_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.sightseeing__search_results .search__result_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.sightseeing__search_results .search__result_row:before, .sightseeing__search_results .search__result_row:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_row:after {
  clear: both;
}
.sightseeing__search_results .search__result_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.sightseeing__search_results .search__result_card:before, .sightseeing__search_results .search__result_card:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_card:after {
  clear: both;
}
.sightseeing__search_results .search__result_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.sightseeing__search_results .search__result_footer:before, .sightseeing__search_results .search__result_footer:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_footer:after {
  clear: both;
}
.sightseeing__search_results .search__result_wrapper:before, .sightseeing__search_results .search__result_wrapper:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_wrapper:after {
  clear: both;
}
.sightseeing__search_results .search__result_wrapper .alert__message {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}
.sightseeing__search_results .search__result_row {
  position: relative;
  text-transform: none;
  text-align: center;
}
.sightseeing__search_results .search__result_row .recommended-product {
  position: absolute;
  z-index: 1;
  transition: 0.3s;
  border-style: solid;
  width: 0px;
  height: 0px;
  line-height: 0px;
  border-color: #444 transparent transparent;
  border-width: 40px 40px 0px 0px;
}
.sightseeing__search_results .search__result_row .recommended-product span {
  transform: rotate(30deg);
  color: #dea310;
  position: absolute;
  top: -38px;
  display: inline-block;
  left: 3px;
  font-size: 20px;
}
.sightseeing__search_results .search__result_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.sightseeing__search_results .search__result_card:before, .sightseeing__search_results .search__result_card:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_card:after {
  clear: both;
}
.sightseeing__search_results .search__result_sightseeing, .sightseeing__search_results .search__result_price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.sightseeing__search_results .search__result_tax-header {
  margin-top: -22px;
  margin-left: 20.6rem;
  margin-right: 1.9rem;
  padding: 0.25rem;
  color: #1d771d;
  background-color: #F5D76E;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}
.sightseeing__search_results .search__result_tax-header:before, .sightseeing__search_results .search__result_tax-header:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_tax-header:after {
  clear: both;
}
.sightseeing__search_results .search__result_tax-header .tax-message {
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.sightseeing__search_results .search__result_header {
  text-transform: uppercase;
  color: #087dc2;
  border-bottom: 1px solid #aac7d8;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  margin-bottom: 0.375rem;
  margin-left: -0.9375rem;
  margin-right: -15px;
}
.sightseeing__search_results .search__result_header .selected-date {
  float: right;
  color: #087dc2;
}
.sightseeing__search_results .search__result_header .delete-sight-seeing {
  margin-right: 15px;
  float: right;
  color: #087dc2;
  cursor: pointer;
}
.sightseeing__search_results .search__result_detail {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #c7e5f6;
  min-height: calc(1.875rem / 3 + 80px);
}
.sightseeing__search_results .search__result_description, .sightseeing__search_results .search__result_extra-desc {
  padding-top: 0.625rem;
  width: 100%;
}
.sightseeing__search_results .search__result_description:before, .sightseeing__search_results .search__result_description:after, .sightseeing__search_results .search__result_extra-desc:before, .sightseeing__search_results .search__result_extra-desc:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search__result_description:after, .sightseeing__search_results .search__result_extra-desc:after {
  clear: both;
}
.sightseeing__search_results .search__result_description {
  padding-bottom: 0.625rem;
}
.sightseeing__search_results .search__result_sightseeing {
  padding: 0;
}
.sightseeing__search_results .search__result-sightseeing, .sightseeing__search_results .search__result-description {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  margin-bottom: 15px;
}
.sightseeing__search_results .search__result-sightseeing img, .sightseeing__search_results .search__result-description img {
  width: 100%;
  max-height: 80px;
  cursor: pointer;
  padding: 1px;
}
.sightseeing__search_results .search__result_price {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.sightseeing__search_results .search__result_trip {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #ccebfd;
}
.sightseeing__search_results .search__result_from, .sightseeing__search_results .search__result_to, .sightseeing__search_results .search__result_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
}
.sightseeing__search_results .result__fname {
  text-transform: capitalize;
}
.sightseeing__search_results .result__sightseeing_heading {
  font-size: 1.125rem;
  color: #087dc2;
  font-weight: 400;
}
.sightseeing__search_results .result__sightseeing_data-header {
  margin-right: 0.313rem;
  text-transform: capitalize;
  font-size: 0.688rem;
}
.sightseeing__search_results .result__description {
  padding-top: 0.9375rem;
}
.sightseeing__search_results .result__description_heading {
  font-size: 1.125rem;
  color: #087dc2;
  font-weight: 400;
}
.sightseeing__search_results .result__description_data {
  float: left;
  width: 45%;
}
.sightseeing__search_results .result__description_data-header {
  margin-right: 5px;
  text-transform: capitalize;
  font-size: 0.688rem;
}
.sightseeing__search_results .result__description_cangellation-policy {
  color: #087dc2;
}
.sightseeing__search_results .result__price-pax_data-header {
  display: inline-block;
  font-weight: bold;
  color: #087dc2;
}
.sightseeing__search_results .result__price {
  height: 76%;
  padding-top: 0.9375rem;
}
.sightseeing__search_results .result__price_amount, .sightseeing__search_results .result__price_amount-with-adhoc {
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: #ec2227;
}
.sightseeing__search_results .result__price_amount-with-adhoc {
  color: #0000FF;
}
.sightseeing__search_results .result__price_adhoc-amount {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.sightseeing__search_results .result__status {
  position: absolute;
  bottom: 0px;
  width: 18%;
  text-align: center;
  text-transform: uppercase;
}
.sightseeing__search_results .result__price-status {
  width: 100%;
  text-transform: uppercase;
}
.sightseeing__search_results .result__extra-details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  text-transform: capitalize;
}
.sightseeing__search_results .result__sightseeing_highlights, .sightseeing__search_results .result__sightseeing_options {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  min-height: 25px;
  text-align: center;
  width: 20%;
  font-size: 0.625rem;
  padding: 0.3125rem 0;
  text-transform: uppercase;
}
.sightseeing__search_results .result__sightseeing_highlights .highlights, .sightseeing__search_results .result__sightseeing_options .highlights {
  padding: 0.3125rem 0;
  background-color: #c7e5f6;
}
.sightseeing__search_results .result__sightseeing_highlights .highlights:hover, .sightseeing__search_results .result__sightseeing_highlights .highlights:active, .sightseeing__search_results .result__sightseeing_highlights .highlights:focus, .sightseeing__search_results .result__sightseeing_options .highlights:hover, .sightseeing__search_results .result__sightseeing_options .highlights:active, .sightseeing__search_results .result__sightseeing_options .highlights:focus {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.sightseeing__search_results .result__sightseeing_highlights .highlights:hover, .sightseeing__search_results .result__sightseeing_options .highlights:hover {
  background-color: #c7e5f6;
  text-decoration: underline;
}
.sightseeing__search_results .result__sightseeing_highlights > a, .sightseeing__search_results .result__sightseeing_options > a {
  color: #087dc2;
  cursor: pointer;
}
.sightseeing__search_results .result__sightseeing_extra_supplier-name-cancellation-policy {
  border-left: 1px solid #c7e5f6;
  border-right: 1px solid #c7e5f6;
  width: 60%;
  display: table;
  float: left;
}
.sightseeing__search_results .result__sightseeing_extra_details, .sightseeing__search_results .result__sightseeing_extra_cancellation-policy, .sightseeing__search_results .result__sightseeing_extra_sightseeing-details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  min-height: 1.563rem;
  background-color: #f0f5f8;
  text-align: left;
  font-size: 0.625rem;
  width: 60%;
  padding-right: 0.625rem;
  color: #087dc2;
}
.sightseeing__search_results .result__sightseeing_extra_details > a, .sightseeing__search_results .result__sightseeing_extra_cancellation-policy > a, .sightseeing__search_results .result__sightseeing_extra_sightseeing-details > a {
  text-transform: uppercase;
  float: right;
  color: #087dc2;
  position: relative;
  padding: 0.3125rem 0;
  width: 30%;
  margin-right: 2%;
  text-align: center;
  background-color: #c7e5f6;
}
.sightseeing__search_results .result__sightseeing_extra_details > a:hover, .sightseeing__search_results .result__sightseeing_extra_details > a:active, .sightseeing__search_results .result__sightseeing_extra_details > a:focus, .sightseeing__search_results .result__sightseeing_extra_cancellation-policy > a:hover, .sightseeing__search_results .result__sightseeing_extra_cancellation-policy > a:active, .sightseeing__search_results .result__sightseeing_extra_cancellation-policy > a:focus, .sightseeing__search_results .result__sightseeing_extra_sightseeing-details > a:hover, .sightseeing__search_results .result__sightseeing_extra_sightseeing-details > a:active, .sightseeing__search_results .result__sightseeing_extra_sightseeing-details > a:focus {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.sightseeing__search_results .result__sightseeing_extra_details > a:hover, .sightseeing__search_results .result__sightseeing_extra_cancellation-policy > a:hover, .sightseeing__search_results .result__sightseeing_extra_sightseeing-details > a:hover {
  background-color: #c7e5f6;
  text-decoration: underline;
}
.sightseeing__search_results .result__sightseeing_extra_cancellation-policy {
  width: 45%;
  cursor: pointer;
}
.sightseeing__search_results .result__sightseeing_extra_sightseeing-details {
  width: 25%;
}
.sightseeing__search_results .result__sightseeing_highlights-active, .sightseeing__search_results .result__sightseeing_options-active {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  min-height: 25px;
  background-color: #c7e5f6;
  padding-top: 5px;
  margin-left: -5px;
  text-align: center;
}
.sightseeing__search_results .result__sightseeing {
  text-align: center;
  max-height: 100px;
}
.sightseeing__search_results .result__sightseeing img {
  width: 100%;
  max-height: 91px;
  padding: 0.635rem;
}
.sightseeing__search_results .result__book, .sightseeing__search_results .result__select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: right;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.sightseeing__search_results .result__book_button, .sightseeing__search_results .result__book_button-active, .sightseeing__search_results .result__select_button, .sightseeing__search_results .result__select_button-active {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.sightseeing__search_results .result__book_button:focus, .sightseeing__search_results .result__book_button.focus, .sightseeing__search_results .result__book_button:active:focus, .sightseeing__search_results .result__book_button:active.focus, .sightseeing__search_results .result__book_button.active:focus, .sightseeing__search_results .result__book_button.active.focus, .sightseeing__search_results .result__book_button-active:focus, .sightseeing__search_results .result__book_button-active.focus, .sightseeing__search_results .result__book_button-active:active:focus, .sightseeing__search_results .result__book_button-active:active.focus, .sightseeing__search_results .result__book_button-active.active:focus, .sightseeing__search_results .result__book_button-active.active.focus, .sightseeing__search_results .result__select_button:focus, .sightseeing__search_results .result__select_button.focus, .sightseeing__search_results .result__select_button:active:focus, .sightseeing__search_results .result__select_button:active.focus, .sightseeing__search_results .result__select_button.active:focus, .sightseeing__search_results .result__select_button.active.focus, .sightseeing__search_results .result__select_button-active:focus, .sightseeing__search_results .result__select_button-active.focus, .sightseeing__search_results .result__select_button-active:active:focus, .sightseeing__search_results .result__select_button-active:active.focus, .sightseeing__search_results .result__select_button-active.active:focus, .sightseeing__search_results .result__select_button-active.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__search_results .result__book_button:hover, .sightseeing__search_results .result__book_button:focus, .sightseeing__search_results .result__book_button.focus, .sightseeing__search_results .result__book_button-active:hover, .sightseeing__search_results .result__book_button-active:focus, .sightseeing__search_results .result__book_button-active.focus, .sightseeing__search_results .result__select_button:hover, .sightseeing__search_results .result__select_button:focus, .sightseeing__search_results .result__select_button.focus, .sightseeing__search_results .result__select_button-active:hover, .sightseeing__search_results .result__select_button-active:focus, .sightseeing__search_results .result__select_button-active.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.sightseeing__search_results .result__book_button:active, .sightseeing__search_results .result__book_button.active, .sightseeing__search_results .result__book_button-active:active, .sightseeing__search_results .result__book_button-active.active, .sightseeing__search_results .result__select_button:active, .sightseeing__search_results .result__select_button.active, .sightseeing__search_results .result__select_button-active:active, .sightseeing__search_results .result__select_button-active.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__search_results .result__book_button.disabled, .sightseeing__search_results .result__book_button[disabled], fieldset[disabled] .sightseeing__search_results .result__book_button, .sightseeing__search_results .result__book_button-active.disabled, .sightseeing__search_results .result__book_button-active[disabled], fieldset[disabled] .sightseeing__search_results .result__book_button-active, .sightseeing__search_results .result__select_button.disabled, .sightseeing__search_results .result__select_button[disabled], fieldset[disabled] .sightseeing__search_results .result__select_button, .sightseeing__search_results .result__select_button-active.disabled, .sightseeing__search_results .result__select_button-active[disabled], fieldset[disabled] .sightseeing__search_results .result__select_button-active {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__search_results .result__select {
  border-color: #ccebfd;
}
.sightseeing__search_results .result__select_button {
  color: #fff;
  background-color: #087dc2;
}
.sightseeing__search_results .result__select_button:focus, .sightseeing__search_results .result__select_button.focus, .sightseeing__search_results .result__select_button:active:focus, .sightseeing__search_results .result__select_button:active.focus, .sightseeing__search_results .result__select_button.active:focus, .sightseeing__search_results .result__select_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__search_results .result__select_button:hover, .sightseeing__search_results .result__select_button:focus, .sightseeing__search_results .result__select_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #043e60;
}
.sightseeing__search_results .result__select_button:active, .sightseeing__search_results .result__select_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__search_results .result__select_button.disabled, .sightseeing__search_results .result__select_button[disabled], fieldset[disabled] .sightseeing__search_results .result__select_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__search_results .result__select_button-active {
  color: #087dc2;
  background-color: #c7e5f6;
}
.sightseeing__search_results .result__select_button-active:focus, .sightseeing__search_results .result__select_button-active.focus, .sightseeing__search_results .result__select_button-active:active:focus, .sightseeing__search_results .result__select_button-active:active.focus, .sightseeing__search_results .result__select_button-active.active:focus, .sightseeing__search_results .result__select_button-active.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__search_results .result__select_button-active:hover, .sightseeing__search_results .result__select_button-active:focus, .sightseeing__search_results .result__select_button-active.focus {
  color: #087dc2;
  text-decoration: none;
  background-color: #c7e5f6;
}
.sightseeing__search_results .result__select_button-active:active, .sightseeing__search_results .result__select_button-active.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__search_results .result__select_button-active.disabled, .sightseeing__search_results .result__select_button-active[disabled], fieldset[disabled] .sightseeing__search_results .result__select_button-active {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__search_results .result__select-button-triangle {
  position: relative;
  margin-left: 0.3125rem;
  top: 0.625rem;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-top: 7px solid #fff;
}
.sightseeing__search_results .result__select-button-triangle-active {
  position: relative;
  margin-left: 5px;
  top: 10px;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-top: 7px solid #087dc2;
}

.description__sightseeing {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 0;
  width: 100%;
  border-bottom: 2px solid #f0f5f8;
  padding-bottom: 0.09375rem;
  padding-top: 0.1875rem;
}
.description__sightseeing_name, .description__sightseeing_city {
  max-height: 100px;
  text-align: left;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  color: #087dc2;
  font-size: 0.865rem;
  font-weight: 400;
  text-transform: uppercase;
}
.description__sightseeing_city {
  color: #838383;
  font-size: 12px;
  font-weight: 600;
}
.description__sightseeing_desc {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  line-height: 1.2;
  max-height: 3.6rem;
}
.description__sightseeing_message {
  color: #ec2227;
}

.sightseeing-result-price-status {
  position: absolute;
  width: 18%;
  bottom: 0px;
  text-align: center;
}
.sightseeing-result-price-status__available, .sightseeing-result-price-status__unavailable, .sightseeing-result-price-status__price-changed {
  margin: 0 auto;
  max-width: 150px;
  color: #FF7000;
  text-transform: uppercase;
}
.sightseeing-result-price-status__available {
  color: #3c763d;
}
.sightseeing-result-price-status__unavailable {
  color: #dd4b39;
}

.highlights {
  cursor: pointer;
}

.sightseeing__search_results .search-info {
  background-color: #087dc2;
}
.sightseeing__search_results .search-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.sightseeing__search_results .search-info_main:before, .sightseeing__search_results .search-info_main:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-info_main:after {
  clear: both;
}
.sightseeing__search_results .search-info_main .content {
  padding-left: 0;
}
.sightseeing__search_results .search-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .sightseeing__search_results .search-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .sightseeing__search_results .search-info_wrapper {
    max-width: 1400px;
  }
}
.sightseeing__search_results .search-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.sightseeing__search_results .search-info_wrapper:before, .sightseeing__search_results .search-info_wrapper:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-info_wrapper:after {
  clear: both;
}
.sightseeing__search_results .search-info__data {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  float: left;
  color: #f0f5f8;
  font-size: 1rem;
  display: block;
}
.sightseeing__search_results .search-info__modify {
  padding-top: 1px;
  padding-bottom: 1px;
  float: right;
}
.sightseeing__search_results .search-info__modify_button {
  padding: 0.3125rem 0.9375rem;
  font-size: 1rem;
  line-height: inherit;
  border-radius: 0;
  color: #087dc2;
  background-color: #c7e5f6;
}
.sightseeing__search_results .search-info__modify_button:focus, .sightseeing__search_results .search-info__modify_button.focus, .sightseeing__search_results .search-info__modify_button:active:focus, .sightseeing__search_results .search-info__modify_button:active.focus, .sightseeing__search_results .search-info__modify_button.active:focus, .sightseeing__search_results .search-info__modify_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__search_results .search-info__modify_button:hover, .sightseeing__search_results .search-info__modify_button:focus, .sightseeing__search_results .search-info__modify_button.focus {
  color: #087dc2;
  text-decoration: none;
  background-color: #9bd1ef;
}
.sightseeing__search_results .search-info__modify_button:active, .sightseeing__search_results .search-info__modify_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__search_results .search-info__modify_button.disabled, .sightseeing__search_results .search-info__modify_button[disabled], fieldset[disabled] .sightseeing__search_results .search-info__modify_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__search_results .search-details {
  background-color: #fff;
}
.sightseeing__search_results .search-details_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.sightseeing__search_results .search-details_main:before, .sightseeing__search_results .search-details_main:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-details_main:after {
  clear: both;
}
.sightseeing__search_results .search-details_main .content {
  padding-left: 0;
}
.sightseeing__search_results .search-details_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .sightseeing__search_results .search-details_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .sightseeing__search_results .search-details_wrapper {
    max-width: 1400px;
  }
}
.sightseeing__search_results .search-details_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.sightseeing__search_results .search-details_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}
.sightseeing__search_results .search-details__row:before, .sightseeing__search_results .search-details__row:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-details__row:after {
  clear: both;
}
.sightseeing__search_results .search-details__row > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  padding-left: 0;
  padding-right: 0;
  border-right: 2px solid #c7e5f6;
}
.sightseeing__search_results .search-details__row > li:first-child {
  border-left: 2px solid #c7e5f6;
}
.sightseeing__search_results .search-details__row > li:nth-child(3) {
  border-right: none;
}
.sightseeing__search_results .search-details__row > li:last-child {
  padding-right: 0.9375rem;
  border-right: none;
}
.sightseeing__search_results .search-details__row_traveller {
  margin-right: -15px;
}
.sightseeing__search_results .search-details .adult__data,
.sightseeing__search_results .search-details .child__data,
.sightseeing__search_results .search-details .infants__data {
  display: block;
}
.sightseeing__search_results .search-details .adult__data_heading,
.sightseeing__search_results .search-details .child__data_heading,
.sightseeing__search_results .search-details .infants__data_heading {
  color: #087dc2;
}
.sightseeing__search_results .search-details .adult__data_value,
.sightseeing__search_results .search-details .child__data_value,
.sightseeing__search_results .search-details .infants__data_value {
  color: #838383;
}
.sightseeing__search_results .search-details .oneway__data,
.sightseeing__search_results .search-details .return__data {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.sightseeing__search_results .search-details .oneway__data:before, .sightseeing__search_results .search-details .oneway__data:after,
.sightseeing__search_results .search-details .return__data:before,
.sightseeing__search_results .search-details .return__data:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-details .oneway__data:after,
.sightseeing__search_results .search-details .return__data:after {
  clear: both;
}
.sightseeing__search_results .search-details .oneway__data_icon,
.sightseeing__search_results .search-details .return__data_icon {
  float: left;
  font-size: 2.25rem;
  width: 20%;
}
.sightseeing__search_results .search-details .oneway__data_detail,
.sightseeing__search_results .search-details .return__data_detail {
  float: left;
  padding: 9px;
  width: 80%;
}
.sightseeing__search_results .search-details .oneway__data_detail .location,
.sightseeing__search_results .search-details .return__data_detail .location {
  color: #087dc2;
}
.sightseeing__search_results .search-details .oneway__data_detail .departure:before, .sightseeing__search_results .search-details .oneway__data_detail .departure:after,
.sightseeing__search_results .search-details .return__data_detail .departure:before,
.sightseeing__search_results .search-details .return__data_detail .departure:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-details .oneway__data_detail .departure:after,
.sightseeing__search_results .search-details .return__data_detail .departure:after {
  clear: both;
}
.sightseeing__search_results .search-details .oneway__data_detail .departure .departure__heading,
.sightseeing__search_results .search-details .return__data_detail .departure .departure__heading {
  float: left;
}
.sightseeing__search_results .search-details .oneway__data_detail .departure .departure__date,
.sightseeing__search_results .search-details .return__data_detail .departure .departure__date {
  float: left;
}
.sightseeing__search_results .search-details .oneway__data_icon span.glyphicon.glyphicon-plane {
  transform: rotate(45deg);
  top: 5px;
}
.sightseeing__search_results .search-details .return__data_icon span.glyphicon.glyphicon-plane {
  transform: rotate(-45deg);
  top: 5px;
}
.sightseeing__search_results .search-details .traveller__row:before, .sightseeing__search_results .search-details .traveller__row:after {
  content: " ";
  display: table;
}
.sightseeing__search_results .search-details .traveller__row:after {
  clear: both;
}
.sightseeing__search_results .search-details .traveller__row > li {
  text-align: center;
  width: 33.33%;
  border-left: 2px solid #c7e5f6;
  padding-top: 9px;
  padding-bottom: 9px;
  float: left;
}
.sightseeing__search_results .search-details .traveller__row > li:last-child {
  border-right: 2px solid #c7e5f6;
}

.sightseeing__pax-info {
  padding-left: 0.9375rem;
}
.sightseeing__pax-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.sightseeing__pax-info_main:before, .sightseeing__pax-info_main:after {
  content: " ";
  display: table;
}
.sightseeing__pax-info_main:after {
  clear: both;
}
.sightseeing__pax-info_main .content {
  padding-left: 0;
}
.sightseeing__pax-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .sightseeing__pax-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .sightseeing__pax-info_wrapper {
    max-width: 1400px;
  }
}
.sightseeing__pax-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.sightseeing__pax-info_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.sightseeing__pax-info_card:before, .sightseeing__pax-info_card:after {
  content: " ";
  display: table;
}
.sightseeing__pax-info_card:after {
  clear: both;
}
.sightseeing__pax-info_card_header {
  padding: 0.375rem 0.9375rem;
}
.sightseeing__pax-info_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.sightseeing__pax-info_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.sightseeing__pax-info_header ul:before, .sightseeing__pax-info_header ul:after {
  content: " ";
  display: table;
}
.sightseeing__pax-info_header ul:after {
  clear: both;
}
.sightseeing__pax-info_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.sightseeing__pax-info_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.sightseeing__pax-info_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.sightseeing__pax-info_card_content {
  margin-bottom: 0.9375rem;
}
.sightseeing__pax-info_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.sightseeing__pax-info_card_header {
  text-transform: uppercase;
  color: #087dc2;
}
.sightseeing__pax-info_card_info {
  color: #ec2227;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-left: 0.2rem;
}
.sightseeing__pax-info_row:before, .sightseeing__pax-info_row:after {
  content: " ";
  display: table;
}
.sightseeing__pax-info_row:after {
  clear: both;
}
.sightseeing__pax-info_header ul > li {
  float: left;
  width: 20%;
  text-align: center;
  font-size: 0.625rem;
}
.sightseeing__pax-info_header ul > li:nth-child(1), .sightseeing__pax-info_header ul > li:nth-child(2) {
  width: 10%;
  text-align: center;
}
.sightseeing__pax-info_header ul > li:last-child {
  width: 20%;
  text-align: center;
}
.sightseeing__pax-info_footer {
  border-top: 1px solid #087dc2;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.sightseeing__pax-info_footer:before, .sightseeing__pax-info_footer:after {
  content: " ";
  display: table;
}
.sightseeing__pax-info_footer:after {
  clear: both;
}
.sightseeing__pax-info .footer_text {
  margin-bottom: 0;
}
.sightseeing__pax-info_room {
  margin-bottom: 0.9375rem;
  border-top: 1px solid #087dc2;
}
.sightseeing__pax-info_room_text {
  text-transform: uppercase;
  color: #087dc2;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}
.sightseeing__pax-info_room .pax-info {
  padding-bottom: 0;
}
.sightseeing__pax-info_button {
  padding: 0.3125rem 0;
  font-size: 0.875rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  border: none;
  width: 25%;
  font-weight: 700;
  float: right;
}
.sightseeing__pax-info_button:focus, .sightseeing__pax-info_button.focus, .sightseeing__pax-info_button:active:focus, .sightseeing__pax-info_button:active.focus, .sightseeing__pax-info_button.active:focus, .sightseeing__pax-info_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__pax-info_button:hover, .sightseeing__pax-info_button:focus, .sightseeing__pax-info_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.sightseeing__pax-info_button:active, .sightseeing__pax-info_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__pax-info_button.disabled, .sightseeing__pax-info_button[disabled], fieldset[disabled] .sightseeing__pax-info_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__pax-info_save-traveler {
  width: 100%;
}
.sightseeing__pax-info_save-traveler:before, .sightseeing__pax-info_save-traveler:after {
  content: " ";
  display: table;
}
.sightseeing__pax-info_save-traveler:after {
  clear: both;
}
.sightseeing__pax-info_save-traveler_button {
  float: right;
  width: 25%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #fff;
  background-color: #087dc2;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
.sightseeing__pax-info_save-traveler_button:focus, .sightseeing__pax-info_save-traveler_button.focus, .sightseeing__pax-info_save-traveler_button:active:focus, .sightseeing__pax-info_save-traveler_button:active.focus, .sightseeing__pax-info_save-traveler_button.active:focus, .sightseeing__pax-info_save-traveler_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing__pax-info_save-traveler_button:hover, .sightseeing__pax-info_save-traveler_button:focus, .sightseeing__pax-info_save-traveler_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #043e60;
}
.sightseeing__pax-info_save-traveler_button:active, .sightseeing__pax-info_save-traveler_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing__pax-info_save-traveler_button.disabled, .sightseeing__pax-info_save-traveler_button[disabled], fieldset[disabled] .sightseeing__pax-info_save-traveler_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing__pax-info_save-traveler {
  margin-bottom: 0.9375rem;
}

.view__cart {
  float: right;
  padding: 0.3125rem;
}

.sightseeing__pax-info_main .content {
  padding-left: 0.9375rem;
}

.sightseeing-details {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
}
.sightseeing-details__header {
  background-color: #f0f5f8;
  border: 10px solid #c7e5f6;
}
.sightseeing-details__description {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f5f8;
}
.sightseeing-details__description_header {
  color: #087dc2;
  margin-bottom: 5px;
}
.sightseeing-details__description_data {
  margin-bottom: 15px;
}
.sightseeing-details__details {
  width: 100%;
  margin-bottom: 15px;
}
.sightseeing-details__details:before, .sightseeing-details__details:after {
  content: " ";
  display: table;
}
.sightseeing-details__details:after {
  clear: both;
}
.sightseeing-details__details_duration, .sightseeing-details__details_time, .sightseeing-details__details_pickup, .sightseeing-details__details_dropoff, .sightseeing-details__details_language {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.sightseeing-details__details_header {
  color: #087dc2;
  margin-bottom: 5px;
}
.sightseeing-details__other-details {
  content: "";
  display: table;
  width: 100%;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f5f8;
}
.sightseeing-details__other-details_header {
  color: #087dc2;
  margin-bottom: 5px;
}
.sightseeing-details__other-details_inclusions, .sightseeing-details__other-details_exclusions, .sightseeing-details__other-details_notes {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
}
.sightseeing-details__remarks {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f5f8;
}
.sightseeing-details__remarks_header {
  color: #087dc2;
  margin-bottom: 5px;
}
.sightseeing-details__remarks_data {
  margin-bottom: 15px;
}
.sightseeing-details__list:before, .sightseeing-details__list:after {
  content: " ";
  display: table;
}
.sightseeing-details__list:after {
  clear: both;
}
.sightseeing-details__list > li {
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.sightseeing-details__list > li > a {
  color: #838383;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
}
.sightseeing-details__tab-content {
  background-color: #fff;
  border: 10px solid #c7e5f6;
}
.sightseeing-details .active {
  background-color: #fff;
}
.sightseeing-details .active a {
  color: #ec2227;
}

.sightseeing-options {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: #fff;
  border: 0.625rem solid #c7e5f6;
}
.sightseeing-options:before, .sightseeing-options:after {
  content: " ";
  display: table;
}
.sightseeing-options:after {
  clear: both;
}
.sightseeing-options__language_header, .sightseeing-options__pickup_header, .sightseeing-options__date_header, .sightseeing-options__tour-time_header, .sightseeing-options__essential-notes_header, .sightseeing-options__question-answer_header, .sightseeing-options__price_header, .sightseeing-options__segmentation-groups_header {
  min-height: 1.875rem;
  background-color: #f0f5f8;
  color: #838383;
  text-transform: uppercase;
  padding: 0.3125rem;
}
.sightseeing-options__language_err-msg, .sightseeing-options__pickup_err-msg, .sightseeing-options__date_err-msg, .sightseeing-options__tour-time_err-msg, .sightseeing-options__essential-notes_err-msg, .sightseeing-options__question-answer_err-msg, .sightseeing-options__price_err-msg, .sightseeing-options__segmentation-groups_err-msg {
  text-align: justify;
  padding-top: 1rem;
  padding-left: 1rem;
  color: #ec2227;
}
.sightseeing-options__language_content, .sightseeing-options__pickup_content, .sightseeing-options__date_content, .sightseeing-options__tour-time_content, .sightseeing-options__essential-notes_content, .sightseeing-options__question-answer_content, .sightseeing-options__price_content, .sightseeing-options__segmentation-groups_content {
  padding-left: 1rem;
  text-align: justify;
  padding-top: 1rem;
}
.sightseeing-options__language_content .segment-header, .sightseeing-options__pickup_content .segment-header, .sightseeing-options__date_content .segment-header, .sightseeing-options__tour-time_content .segment-header, .sightseeing-options__essential-notes_content .segment-header, .sightseeing-options__question-answer_content .segment-header, .sightseeing-options__price_content .segment-header, .sightseeing-options__segmentation-groups_content .segment-header {
  color: #087dc2;
}
.sightseeing-options__language_content .segments-name__icon, .sightseeing-options__pickup_content .segments-name__icon, .sightseeing-options__date_content .segments-name__icon, .sightseeing-options__tour-time_content .segments-name__icon, .sightseeing-options__essential-notes_content .segments-name__icon, .sightseeing-options__question-answer_content .segments-name__icon, .sightseeing-options__price_content .segments-name__icon, .sightseeing-options__segmentation-groups_content .segments-name__icon {
  font-size: 0.5rem;
}
.sightseeing-options__language_data, .sightseeing-options__pickup_data, .sightseeing-options__date_data, .sightseeing-options__tour-time_data, .sightseeing-options__essential-notes_data, .sightseeing-options__question-answer_data, .sightseeing-options__price_data, .sightseeing-options__segmentation-groups_data {
  margin-top: 0.9375rem;
}
.sightseeing-options__language_data > select, .sightseeing-options__pickup_data > select, .sightseeing-options__date_data > select, .sightseeing-options__tour-time_data > select, .sightseeing-options__essential-notes_data > select, .sightseeing-options__question-answer_data > select, .sightseeing-options__price_data > select, .sightseeing-options__segmentation-groups_data > select {
  width: 63%;
  padding: 0.5rem 1rem;
  background: none;
  vertical-align: middle;
  line-height: normal;
}
.sightseeing-options__language_data > label, .sightseeing-options__pickup_data > label, .sightseeing-options__date_data > label, .sightseeing-options__tour-time_data > label, .sightseeing-options__essential-notes_data > label, .sightseeing-options__question-answer_data > label, .sightseeing-options__price_data > label, .sightseeing-options__segmentation-groups_data > label {
  padding-left: 1rem;
}
.sightseeing-options__language_marhaba-content, .sightseeing-options__pickup_marhaba-content, .sightseeing-options__date_marhaba-content, .sightseeing-options__tour-time_marhaba-content, .sightseeing-options__essential-notes_marhaba-content, .sightseeing-options__question-answer_marhaba-content, .sightseeing-options__price_marhaba-content, .sightseeing-options__segmentation-groups_marhaba-content {
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  width: 25%;
}
.sightseeing-options__info-message {
  padding-left: 1rem;
  text-align: justify;
  padding-top: 1rem;
  color: #ec2227;
}
.sightseeing-options__pickup, .sightseeing-options__language, .sightseeing-options__tour-time, .sightseeing-options__essential-notes, .sightseeing-options__price, .sightseeing-options__segmentation-groups {
  width: 100%;
}

.option-content {
  display: block;
  width: 100%;
  max-height: 19rem;
  overflow-y: auto;
}
.option-content__loader {
  padding: 0.625rem;
}
.option-content__heading {
  color: #087dc2;
  background-color: #f0f5f8;
  text-transform: uppercase;
  padding: 0.5rem;
  width: 100%;
}
.option-content__heading:before, .option-content__heading:after {
  content: " ";
  display: table;
}
.option-content__heading:after {
  clear: both;
}
.option-content__heading_title {
  width: 70%;
  padding-left: 0.05rem;
  float: left;
}
.option-content__heading_rate, .option-content__heading_currency {
  width: 5%;
  float: left;
}
.option-content__data {
  width: 100%;
  display: table;
  padding: 0.3rem 0.5rem;
  border-bottom: solid 1px #c7e5f6;
}
.option-content__data_select {
  width: 5%;
  padding-top: 0.2rem;
  float: left;
}
.option-content__data_details {
  width: 45%;
  float: left;
  font-family: "Open Sans";
}
.option-content__data_details .details__deductibles {
  color: #ec2227;
  padding-left: 0.3125rem;
}
.option-content__data_details .option-name {
  height: 1.6rem;
  display: inline-block;
  width: 100%;
}
.option-content__data_rate {
  font-family: "Open Sans";
  width: 30%;
  display: inline-block;
  text-align: right;
}
.option-content__data_rate .options-adhoc {
  display: inline-block;
  text-align: center;
}
.option-content .options-button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0;
  float: right;
}
.option-content .options-button__book {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.option-content .options-button__book:focus, .option-content .options-button__book.focus, .option-content .options-button__book:active:focus, .option-content .options-button__book:active.focus, .option-content .options-button__book.active:focus, .option-content .options-button__book.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.option-content .options-button__book:hover, .option-content .options-button__book:focus, .option-content .options-button__book.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.option-content .options-button__book:active, .option-content .options-button__book.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.option-content .options-button__book.disabled, .option-content .options-button__book[disabled], fieldset[disabled] .option-content .options-button__book {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.option-content .options-highlights, .option-content .options-cancellation-policy {
  display: inline-block;
  width: 100%;
  color: #087dc2;
  cursor: pointer;
}
.option-content .options-cancellation-policy {
  padding-right: 0.6rem;
}

.sightseeing__review_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.sightseeing__review_main:before, .sightseeing__review_main:after {
  content: " ";
  display: table;
}
.sightseeing__review_main:after {
  clear: both;
}
.sightseeing__review_main .content {
  padding-left: 0;
}
.sightseeing__review_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .sightseeing__review_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .sightseeing__review_wrapper {
    max-width: 1400px;
  }
}
.sightseeing__review_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.sightseeing__review_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.sightseeing__review_card:before, .sightseeing__review_card:after {
  content: " ";
  display: table;
}
.sightseeing__review_card:after {
  clear: both;
}
.sightseeing__review_main {
  padding-top: 0.9375rem;
}
.sightseeing__review_main .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.sightseeing__review_header .header__container {
  background-color: #f0f5f8;
  padding-top: 0.3125rem;
  text-transform: uppercase;
  width: 100%;
}
.sightseeing__review_header .header__container:before, .sightseeing__review_header .header__container:after {
  content: " ";
  display: table;
}
.sightseeing__review_header .header__container:after {
  clear: both;
}
.sightseeing__review_header .header__container_heading {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  text-align: left;
}
.sightseeing__review_header .header__container_guest-heading {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  text-align: left;
}
.sightseeing__review_header .header__details-container {
  background-color: #f0f5f8;
  padding-top: 0.3125rem;
  text-transform: uppercase;
  width: 100%;
}
.sightseeing__review_header .header__details-container:before, .sightseeing__review_header .header__details-container:after {
  content: " ";
  display: table;
}
.sightseeing__review_header .header__details-container:after {
  clear: both;
}
.sightseeing__review_header .header__details-container_heading {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  text-align: left;
}
.sightseeing__review_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding: 0 0.9375rem;
  padding-bottom: 1rem;
}
.sightseeing__review_card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0px;
  content: "";
  display: table;
  width: 100%;
}
.sightseeing__review_card .header_text {
  margin-right: 0.234375rem;
  font-size: 0.75rem;
}
.sightseeing__review_card .guest_occupancy {
  float: left;
}
.sightseeing__review_card .vat_title {
  float: right;
}
.sightseeing__review_card .inclusive_vat {
  padding-right: 6px;
  color: red;
  font-size: 85%;
}
.sightseeing__review_card_content {
  padding-bottom: 0.9375rem;
  padding-top: 0.9375rem;
}
.sightseeing__review_card .nav {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  background-color: #c7e5f6;
}
.sightseeing__review_card .nav-tabs {
  border-bottom: none;
}
.sightseeing__review_card .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.sightseeing__review_card .nav > li > a:hover,
.sightseeing__review_card .nav > li > a:focus {
  background-color: #fff;
}
.sightseeing__review_card .nav-tabs > li.active > a,
.sightseeing__review_card .nav-tabs > li.active > a:hover,
.sightseeing__review_card .nav-tabs > li.active > a:focus {
  color: #087dc2;
  background-color: #fff;
  border: none;
  cursor: default;
}
.sightseeing__review_card-overview, .sightseeing__review_card-inclusions, .sightseeing__review_card-remarks, .sightseeing__review_card-cancellation-policy {
  padding-bottom: 0.9375rem;
  padding-top: 0.9375rem;
}
.sightseeing__review_card-guest {
  padding-bottom: 0.9375rem;
}
.sightseeing__review_card_questions-required-tab {
  position: relative;
}
.sightseeing__review_card_questions-required-tab > a:after {
  content: "\f069";
  font-family: FontAwesome;
  position: absolute;
  top: 0.625rem;
  font-size: 0.4rem;
  color: #ec2227;
  padding-left: 0.125rem;
  padding-bottom: 0.625rem;
}
.sightseeing__review_card-image {
  float: left;
  min-height: 1px;
  width: 25%;
}
.sightseeing__review_card-image:before, .sightseeing__review_card-image:after {
  content: " ";
  display: table;
}
.sightseeing__review_card-image:after {
  clear: both;
}
.sightseeing__review_row {
  padding-bottom: 0.9375rem;
  padding-top: 0.9375rem;
}
.sightseeing__review_row:before, .sightseeing__review_row:after {
  content: " ";
  display: table;
}
.sightseeing__review_row:after {
  clear: both;
}
.sightseeing__review_row .row__main-details {
  padding-top: 0 0.9375rem;
  text-align: left;
}
.sightseeing__review_row .row__main-details_heading {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  color: #087dc2;
  font-size: 0.875rem;
}
.sightseeing__review_row .row__main-details_data {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  color: #087dc2;
  font-size: 0.875rem;
}
.sightseeing__review_row .row__main-details_option-data {
  width: 100%;
  color: #087dc2;
  font-size: 0.875rem;
  padding: 0.9375rem;
  display: flex;
  padding-bottom: 0;
}
.sightseeing__review_row .row__other-details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  text-align: left;
}
.sightseeing__review_row .row__details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  text-align: left;
}
.sightseeing__review_row .row__remarks {
  text-align: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.sightseeing__review_row .row__guest-occupancy {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  text-align: left;
}
.sightseeing__review_marhaba-detail {
  float: left;
  width: 75%;
  min-height: 5.625rem;
}
.sightseeing__review_marhaba-detail-name {
  max-height: 6.25rem;
  text-align: left;
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 1%;
  padding-top: 1%;
  text-transform: capitalize;
  color: #087dc2;
}
.sightseeing__review_marhaba-detail-name:before, .sightseeing__review_marhaba-detail-name:after {
  content: " ";
  display: table;
}
.sightseeing__review_marhaba-detail-name:after {
  clear: both;
}
.sightseeing__review_marhaba-description {
  padding-bottom: 0.625rem;
  width: 100%;
}
.sightseeing__review_marhaba {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-right: 0.625rem;
  padding-top: 1.875rem;
  padding-bottom: 0.625rem;
}
.sightseeing__review_marhaba .review__insurance > img {
  width: 100%;
}

.auxilary-details {
  border: 2px solid #c7e5f6;
  background-color: #f0f5f8;
  padding: 0 0% 0 0%;
  font-size: 0.875rem;
}
.auxilary-details__headers {
  padding: 0.5rem;
  border-bottom: 1px solid #959B9E;
  color: #087dc2;
}
.auxilary-details__headers:before, .auxilary-details__headers:after {
  content: " ";
  display: table;
}
.auxilary-details__headers:after {
  clear: both;
}
.auxilary-details__headers_label {
  float: left;
  width: 50%;
  word-wrap: break-word;
}
.auxilary-details__headers_amount {
  float: left;
  width: 50%;
  text-align: right;
  font-family: "Open Sans";
}
.auxilary-details__content {
  padding: 0.5rem;
  border-bottom: 1px solid #959B9E;
}
.auxilary-details__content:before, .auxilary-details__content:after {
  content: " ";
  display: table;
}
.auxilary-details__content:after {
  clear: both;
}
.auxilary-details__content:last-child {
  border-bottom: none;
}
.auxilary-details__content_label {
  float: left;
  width: 50%;
  word-wrap: break-word;
}
.auxilary-details__content_amount {
  float: left;
  width: 50%;
  text-align: right;
  font-family: "Open Sans";
}

.essential-notes__header {
  padding-top: 0.9375rem;
  display: inline-block;
  color: #087dc2;
}
.essential-notes__content {
  text-align: justify;
  padding: 0.9375rem;
}

.traveler-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.traveler-card__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.traveler-card__product-header {
  background-color: #EEEFF3;
  padding-left: 0.725rem;
  padding-right: 0.725rem;
  margin-top: -6px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  border-top: 1px solid #087dc2;
  border-bottom: 1px solid #087dc2 !important;
}
.traveler-card__product-header:before, .traveler-card__product-header:after {
  content: " ";
  display: table;
}
.traveler-card__product-header:after {
  clear: both;
}
.traveler-card__product-header_index {
  float: left;
  width: 50%;
  text-align: left;
  color: #087dc2;
}
.traveler-card__product-header_product-type {
  float: left;
  width: 50%;
  text-align: right;
  text-transform: uppercase;
  color: #087dc2;
}
.traveler-card__table {
  font-family: "Open Sans";
  width: 100%;
}
.traveler-card__table_total {
  background-color: #f0f5f8;
  color: #000;
}
.traveler-card__table tr td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.sightseeing-contact__header, .sightseeing-desc__header, .sightseeing-amenity__header {
  min-height: 30px;
  background-color: #f0f5f8;
  color: #838383;
  text-transform: uppercase;
  padding: 5px;
}

.sightseeing-contact__content ul {
  padding-top: 10px;
}
.sightseeing-contact__content ul > li {
  padding-top: 3px;
  border-bottom: 2px solid #f0f5f8;
}
.sightseeing-contact__content ul > li:last-child {
  border-bottom: none;
}

.sightseeing-contact__content, .sightseeing-desc__content, .sightseeing-amenity__content {
  padding-left: 12px;
  padding-bottom: 5px;
}
.sightseeing-contact__content_sightseeing-name, .sightseeing-desc__content_sightseeing-name, .sightseeing-amenity__content_sightseeing-name {
  color: #087dc2;
  font-size: 12px;
}
.sightseeing-contact__content_title, .sightseeing-desc__content_title, .sightseeing-amenity__content_title {
  color: #087dc2;
  font-size: 12px;
  text-transform: uppercase;
}
.sightseeing-contact__content_info, .sightseeing-desc__content_info, .sightseeing-amenity__content_info {
  color: #838383;
  padding-bottom: 2px;
}
.sightseeing-contact__content_info .sightseeing-contact__details, .sightseeing-desc__content_info .sightseeing-contact__details, .sightseeing-amenity__content_info .sightseeing-contact__details {
  font-weight: bold;
  width: 100%;
  padding-left: 0;
  font-size: 0.8rem;
}
.sightseeing-contact__content_info .sightseeing-contact__icon, .sightseeing-desc__content_info .sightseeing-contact__icon, .sightseeing-amenity__content_info .sightseeing-contact__icon {
  font-size: 0.5rem;
}
.sightseeing-contact__content_city, .sightseeing-desc__content_city, .sightseeing-amenity__content_city {
  color: #838383;
}

.sightseeing-desc__content {
  padding-top: 10px;
}

.sightseeing-amenity__content:before, .sightseeing-amenity__content:after {
  content: " ";
  display: table;
}
.sightseeing-amenity__content:after {
  clear: both;
}
.sightseeing-amenity__content_amenity {
  min-width: 25%;
  float: left;
}

.amenity__logo {
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.amenity__info {
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sightseeing-highlights-loader {
  text-align: center;
}

.sightseeing-highlights {
  height: 600px;
  width: 100%;
  content: "";
  display: table;
}
.sightseeing-highlights .highlights__sidebar {
  float: left;
  width: 25%;
  height: 100%;
  background-color: #EEEFF3;
}
.sightseeing-highlights .highlights__sightseeing-details {
  float: right;
  width: 75%;
  height: 100%;
  margin-top: 10px;
}
.sightseeing-highlights .sidebar__back-button {
  margin: 20px;
  background-color: white;
  border: 5px solid #087dc2;
  padding: 25px;
}
.sightseeing-highlights .sidebar__back-button button {
  cursor: pointer;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  background-color: #ec2227;
  color: #fff;
  display: block;
}
.sightseeing-highlights .sidebar__back-button button:hover {
  background-color: #9b0d11;
}
.sightseeing-highlights .sidebar__tabs {
  width: 100%;
  height: 80%;
  padding-bottom: 10px;
}
.sightseeing-highlights .sidebar__tabs > li {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  position: relative;
}
.sightseeing-highlights .sidebar__tabs > li > a {
  color: #087dc2;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
.sightseeing-highlights .sidebar__tabs > li:hover {
  background-color: #087dc2;
}
.sightseeing-highlights .sidebar__tabs > li:hover a {
  color: #fff;
}
.sightseeing-highlights .sidebar__tabs > li:after {
  content: "";
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 100%;
}
.sightseeing-highlights .sidebar__tabs .active {
  background-color: #fff;
}
.sightseeing-highlights .sidebar__tabs .active a {
  color: #ec2227;
}
.sightseeing-highlights .sightseeing-details__sightseeing-info {
  width: 100%;
}
.sightseeing-highlights .sightseeing-details__sightseeing-info:before, .sightseeing-highlights .sightseeing-details__sightseeing-info:after {
  content: " ";
  display: table;
}
.sightseeing-highlights .sightseeing-details__sightseeing-info:after {
  clear: both;
}
.sightseeing-highlights .sightseeing-details__content {
  width: 100%;
  height: 80%;
  padding-left: 15px;
}
.sightseeing-highlights .sightseeing-details__content .modal__content_sightseeing-desc, .sightseeing-highlights .sightseeing-details__content .modal__content_sightseeing-selection {
  margin-top: 1rem;
}
.sightseeing-highlights .sightseeing-info__sightseeing-image {
  float: left;
  padding-left: 15px;
  width: 20%;
}
.sightseeing-highlights .sightseeing-info__sightseeing-image .result__thumbnail {
  width: 100%;
  height: 90px;
}
.sightseeing-highlights .sightseeing-info__sightseeing-description {
  float: left;
  width: 80%;
}
.sightseeing-highlights .sightseeing-info__book {
  padding-left: 1rem;
  padding-right: 1rem;
}
.sightseeing-highlights .sightseeing-info__book > hr {
  border: 0.1rem solid #55595c;
}
.sightseeing-highlights .sightseeing-info__book_button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  float: right;
  width: 20%;
}
.sightseeing-highlights .sightseeing-info__book_button:focus, .sightseeing-highlights .sightseeing-info__book_button.focus, .sightseeing-highlights .sightseeing-info__book_button:active:focus, .sightseeing-highlights .sightseeing-info__book_button:active.focus, .sightseeing-highlights .sightseeing-info__book_button.active:focus, .sightseeing-highlights .sightseeing-info__book_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.sightseeing-highlights .sightseeing-info__book_button:hover, .sightseeing-highlights .sightseeing-info__book_button:focus, .sightseeing-highlights .sightseeing-info__book_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.sightseeing-highlights .sightseeing-info__book_button:active, .sightseeing-highlights .sightseeing-info__book_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.sightseeing-highlights .sightseeing-info__book_button.disabled, .sightseeing-highlights .sightseeing-info__book_button[disabled], fieldset[disabled] .sightseeing-highlights .sightseeing-info__book_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sightseeing-highlights .sightseeing-info__book .price__label {
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: #087dc2;
}
.sightseeing-highlights .sightseeing-info__book .price__amount {
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: #ec2227;
}
.sightseeing-highlights .description__sightseeing {
  padding-left: 10px;
  width: 100%;
}
.sightseeing-highlights .description__sightseeing_name {
  color: #087dc2;
  font-weight: 400;
  text-align: left;
  font-size: 18px;
}
.sightseeing-highlights .sightseeing-slideshow__header {
  min-height: 30px;
  background-color: #f0f5f8;
  color: #838383;
  text-transform: uppercase;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}