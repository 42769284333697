@mixin create-fare-details-extra-content_private($padding-left, $padding-right) {
	border: 2px solid $brand-color-3;
	background-color: $brand-color-4;
	padding: 0 $padding-right 0 $padding-left;
	font-size: .875rem;
	
	&__headers {
		@include clearfix;
    	padding: 0.5rem;
    	border-bottom: 1px solid $separator-color;
    	color: $brand-color-2;
		
		&_label {
			float: left;
			width: 50%;
			word-wrap: break-word;
		}
		&_amount {
			float: left;
			width: 50%;
			text-align: right;
			font-family: $font-family-number;
		}
	}
	
	&__content {
		@include clearfix;
	    padding: 0.5rem;
    	border-bottom: 1px solid $separator-color;
    	
    	&:last-child {
	    	border-bottom: none;
    	}
    	
    	&_label {
			float: left;
			width: 50%;
			word-wrap: break-word;
		}
		&_amount {
			float: left;
			width: 50%;
			text-align: right;
			font-family: $font-family-number;
		}
	}
}

@mixin  create-fare-details-extra-content($padding-left, $padding-right) { 
	@include create-fare-details-extra-content_private($padding-left, $padding-right); 
}