@mixin make-save-traveler-button_private($width) {
  		width: $width;
  		padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
  		@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
    	font-weight: 700;
}

@mixin  make-save-traveler-button($width) { 
	@include make-save-traveler-button_private($width); 
}