@use "sass:math";

.gm-style .gm-style-iw {
	overflow: visible !important;
	font-weight: 400 !important;
	padding-left: 0px;
}

.gm-style-iw>div {
	overflow: visible !important;
	line-height: 1.35;

	// white-space: nowrap;
}

.sightseeing__infowindow {
	&_content {
		@include make-row;
		padding-bottom: $base-padding*0.5;
		background-color: $light;
		text-transform: capitalize;
		width: 100%;
		margin-left: 0;
	}

	&_detail {
		float: left;
		width: 100%;
		padding-left: $base-padding*0.5;
		padding-right: math.div($base-padding, 6);
	}

	&_price {
		float: left;
		width: 100%;
		padding-right: $base-padding*0.5;
		padding-left: math.div($base-padding, 6);
		padding-top: $base-padding*0.5;
		text-align: right;
	}

	.infowindow__close {
		display: none;
	}
}

.infowindow {
	&__sightseeing {
		&_name {
			color: $brand-color-2;
			font-size: 1rem;
			font-weight: 400;
		}
	}

	&__roomdesc {
		&_text {
			color: $brand-color-5;
			font-size: 0.7rem;
		}
	}

	&__rating {
		float: left;
		width: 50%;
		text-align: center;
		padding-top: $base-padding*0.5;

		.glyphicon {
			color: $star-rating;
		}
	}

	&__price {
		float: left;
		width: 50%;
		text-align: center;
		max-height: 100px;

		&_amount {
			font-size: $font-size-h3;
			font-family: $font-family-number;
			color: lighten($brand-color-1, 20);
		}
	}
}

.custom-info {
	max-width: 300px;
	position: absolute;
	top: 5%;
	right: 5%;
	box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 2px;

	.sightseeing__infowindow {
		&_content {
			margin-left: 0;
			margin-right: 0;
			padding-top: $base-padding*0.5;
		}

		&_footer_links {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.infowindow__close {
		display: block;
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;

		&:hover {
			color: darken($brand-color-5, 20);
		}
	}
}