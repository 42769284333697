@use "sass:math";

.sightseeing-details {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: math.div($base-padding, 3);
  padding-right: math.div($base-padding, 3);
  padding-bottom: math.div($base-padding, 3);
  &__header {
    background-color: $brand-color-4;
    border: 10px solid $brand-color-3;
    //border-right: 10px solid $brand-color-3;
    //border-top: 10px solid $brand-color-3;
  }
  
  &__description{
   margin-left: 15px;
  margin-right: 15px;
  margin-bottom:15px;
  border-bottom:1px solid $brand-color-4;
  &_header{
  color:$row-time-color;
  margin-bottom:5px;
  }
  &_data{
  margin-bottom:15px;
  }
  }
  &__details{
  @include clearfix;
  width: 100%;
  margin-bottom:15px;
  &_duration,&_time,&_pickup,&_dropoff,&_language{
  @include make-one-fifth;
  }
  &_header{
  color: $row-time-color;
  margin-bottom:5px;
  }
  &_data{
  
  }
 }
  &__other-details{
  content: "";
  display: table;
  width: 100%;
  margin-bottom:15px;
  border-bottom:1px solid $brand-color-4;
  
  &_header{
  color: $row-time-color;
  margin-bottom:5px;
  }
  &_data{
  
  }
  &_inclusions,&_exclusions,&_notes{
  @include make-one-third;
  }
  }
   &__remarks{
    margin-left: 15px;
  margin-right: 15px;
  margin-bottom:15px;
  border-bottom:1px solid $brand-color-4;
  &_header{
  color:$row-time-color;
  margin-bottom:5px;
  }
  &_data{
  margin-bottom:15px;
  }
 
   
   }
   
  &__list {

    // display: inline-block;
    @include clearfix;
    >li {
      padding-top: 5px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      float: left;
      // border-top: 3px solid $brand-color-3;
      // border-left: 3px solid $brand-color-3;
      // border-right: 3px solid $brand-color-3;
      > a {
        color: $brand-color-5;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
      }

      //
      // .active{
      //   background-color: $brand-color-3;
      //   color: $brand-color-2;
      // }
    }
  }
  
  &__tab-content {
    background-color: $light;
    border: 10px solid $brand-color-3;
     }
  .active {
    background-color: $light;
    a {
      color: $brand-color-1;
    }
  }
}