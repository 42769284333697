.sightseeing{
	@include create-search-form;
	@include create-search-form-nav;
	&__search-currency-inline {
			margin-left: -.9375rem;
			margin-right: 1.25rem;
	}

	&__search-form_container {
		&_sightseeing-type{
			@include clearfix;
		    background-color: $sf-triptype-bgcolor;
		    padding-top: 0.5rem;
		    padding-left: $base-padding*0.5;
		    padding-right: $base-padding*0.5;
			    .radio-inline {
			      @include make-one-third;
			    }
		}
	.search-vehicle-type {
		@include clearfix;
		    background-color: #c7e5f6;
		    padding-top: 0.5rem;
		    margin-left: -1.875rem;
		    margin-right: -1.875rem;
		    padding-left: 0.9375rem;
		    padding-right: 0.9375rem;
		    margin-bottom: 0.9375rem;
		    
		&_SIC,&_private-vehicle {
			float: left;
			padding-left: 0.9375rem;
		    padding-right: 0.9375rem;
		    min-height: 1px;
		    width: 33.33333%;
		}
	}
}

&__search-traveller-selector{
margin-left: -15px;
margin-right: -15px;
}
}
.transfer {
	@include create-search-form;
	@include create-search-form-nav;

	&__search-vehicle-type{
		margin-left: 0;
	    margin-right: 0;
	}

  &__search-pickupdropoff-container,
  &__search-vehicle-type, {
    margin-bottom: 1rem;
  }

  &__search-vehicle-type {
    @include clearfix;
    background-color: $sf-triptype-bgcolor;
    padding-top: 0.5rem;
    padding-left: $base-padding*0.5;
    padding-right: $base-padding*0.5;
    .radio-inline {
      @include make-one-third;
    }
  }

  &__search-pickupdropoff-container {
      @include make-row;
      &_pickup-wrapper, &_dropoff-wrapper {
        @include make-one-half;
      }
  }

  &__search-airport-select-container {
    @include make-row;
    &_pickup-point-airport, &_dropoff-point-airport {
      @include make-one-half;
    }
  }

  &__search-time, &__search-date {
  @include make-row;
   margin-bottom: 1rem;
  	&_select{
  	@include make-one-half;
  	}
  }

}

.pickup-wrapper, .dropoff-wrapper {
  &__pickup-point, &__dropoff-point {
      &_title {
        color: $brand-color-2;
        font-size: .875rem;
      }
  }

  &__pickup-point-airport, &__dropoff-point-airport {
    margin-top: 1rem;
    &_title {
      color: $brand-color-2;
      font-size: .875rem;
    }
  }

}
