@use "sass:math";

.sightseeing__review {
  @include create-main-wrapper;
 // @include create-row;
  @include create-row-card;
 //@include create-row-card-header;
  //@include create-card-content;
  //@include create-row-card-footer;
  //include create-flight-row;
  &_main {
    padding-top: $base-padding*0.5;
    .content {
      padding-left: $base-padding*0.5;
      padding-right: 0;
    }
    }
    &_header{
    .header__container{
    @include clearfix;
    background-color: $brand-color-4;
    padding-top: math.div($base-padding, 6);
    text-transform: uppercase;
     
    width:100%;
    &_heading{
    @include make-one-third;
    text-align:left;
    }
    &_guest-heading{
    @include make-one-sixth;
    text-align:left;
    }    
    }
    .header__details-container{
    @include clearfix;
    background-color: $brand-color-4;
    padding-top: math.div($base-padding, 6);
    text-transform: uppercase;
     
    width:100%;
    &_heading{
    @include make-one-fifth;
    text-align:left;
    }
    
    }
    }
  
   &_card {
    box-shadow: $row-shadow-color;
    padding:0 .9375rem;
    padding-bottom:1rem;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-left: 0px;
      content: '';
      display: table;
      width: 100%;
    }
    .header_text {
      margin-right: $base-margin*0.125;
      font-size:$font-size-h5;
    }
    .guest_occupancy
    {
        float: left;
    }
    .vat_title
    {
        float: right;
    }
    .inclusive_vat
    {
    padding-right: 6px;    
    color: red;
    font-size: 85%;
    }
    &_content{
    
    padding-bottom: .9375rem;
    padding-top: .9375rem;
    }
    @include create-review-nav;
    
    &-overview,&-inclusions,&-remarks,&-cancellation-policy{
    padding-bottom:0.9375rem;
    padding-top:0.9375rem;
  }
    &-guest{
    padding-bottom:0.9375rem;
  }
	&_questions-required-tab{
		position:relative;
		> a{
			&:after{
				content: "\f069";
				font-family: FontAwesome;
				position: absolute;
		    	top: 0.625rem;
		    	font-size: 0.4rem;
		   		color: $brand-color-37;
		    	padding-left: 0.125rem;
		   	 	padding-bottom: 0.625rem;
			}
		}
	}
  &-image{
    @include clearfix();
    float: left;
    min-height: 1px;
    width: 25%;
  }
  }

  &_row{
  @include clearfix;
  padding-bottom:0.9375rem;
  padding-top:0.9375rem;
    .row{
    &__main-details{
    padding-top:0 .9375rem;
    text-align:left;
    &_heading{
    @include make-one-third;
    color: $brand-color-2;
    font-size:$font-size-h4;
    }
    &_data{
    @include make-one-third;
    color: $brand-color-2;
    font-size:$font-size-h4;
    }
    &_option-data{
    	width: 100%;
    	color: $brand-color-2;
    	font-size:$font-size-h4;
    	padding: $base-padding*0.5;
    	display: flex;
    	padding-bottom: 0;
    }
    }
    
    &__other-details{
    @include make-one-third;
    text-align:left;
    
    }
    
    &__details{
    @include make-one-fifth;
    text-align:left;
    }
	&__remarks{
	text-align:left;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    
    }
    &__guest-occupancy{
    @include make-one-sixth;
    text-align:left;
    
    
    }
    
    }
  }

  &_marhaba-detail{
      float: left;
      width: 75%;
      min-height: 5.625rem;
  }
  &_marhaba-detail-name{
    @include clearfix();
    max-height: 6.25rem;
    text-align: left;
    font-family: Open Sans;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 1%;
    padding-top: 1%;
    text-transform: capitalize;
    color: $brand-color-2;
  }
  &_marhaba-description{
    padding-bottom: 0.625rem;
    width: 100%;
  }
  &_marhaba {
    @include make-one-fifth;
    padding-right: math.div($base-padding, 3);
    padding-top: $base-padding;
    padding-bottom: math.div($base-padding, 3);
    .review {
     &__insurance {
       >img {
         width:100%;
       }
      }
    }
  }
}
.auxilary-details{
  @include create-fare-details-extra-content(0%, 0%);
}

.essential-notes{
	&__header{
    	padding-top: $base-padding*0.5;
		display: inline-block;
	    color: $brand-color-2;
	}
	&__content{
		text-align: justify;
	    padding: $base-padding*0.5;
	}
}

