@use "sass:math";

.sightseeing-options {
  @include clearfix;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px;
  padding-left: math.div($base-padding, 3);
  padding-right: math.div($base-padding, 3);
  padding-bottom: math.div($base-padding, 3);
  background-color: $light;
  border: 0.625rem solid $brand-color-3;
  &__language,&__pickup,&__date,&__tour-time,&__essential-notes,&__question-answer,&__price,&__segmentation-groups{
	  &_header{
	  	min-height:1.875rem;
		background-color:$brand-color-4;
		color:$brand-color-5;
		text-transform: uppercase;
		padding: 0.3125rem;
	  }
	  &_err-msg{
	  	text-align: justify;
	  	padding-top: 1rem;
	  	padding-left: 1rem;
	  	color: $brand-color-1;
	  }
	  &_content{
	    padding-left:1rem;
	  	text-align: justify;
	  	padding-top: 1rem;
	  	.segment-header{
	  		color: $brand-color-2;
	  	}
	  	.segments-name__icon{
	  		font-size: 0.5rem;
	  	}
	  }
  &_data{
  	margin-top: $base-margin*0.5;
	  > select{
	   	width: 63%;
	    padding: 0.5rem 1rem;
	    background: none;
	    vertical-align: middle;
	    line-height: normal;
	  }
	   >label{
	  	padding-left:1rem;
	  }	
  }
  &_marhaba-content{
	padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
    width: 25%;
  }
}

&__info-message {
	padding-left:1rem;
	text-align: justify;
	padding-top: 1rem;
	color: $brand-color-1;
}

&__pickup,&__language,&__tour-time,&__essential-notes,&__price,&__segmentation-groups{
  	width:100%;
  }
}

.option-content {
	display: block;
    width: 100%;
    max-height: 19rem;
    overflow-y: auto;
    &__loader {
    	padding: math.div($base-padding, 3);
    }
	&__heading {
		color: $brand-color-2;
		background-color: $brand-color-4;
		text-transform: uppercase;
		padding: 0.5rem;
		width: 100%;
		@include clearfix;
		&_title {
			width: 70%;
		    padding-left: 0.05rem;
		    float: left;
		}
		&_rate, &_currency{
			width: 5%;
			float:left;
		}
	}
	&__data {
		width: 100%;
		display: table;
		padding: .3rem .5rem;
    	border-bottom: solid 1px $brand-color-3;
		&_select{
			width: 5%;
		    padding-top: 0.2rem;
		    float: left;
		}
		&_details{
			width: 45%;
		    float:left;
		    font-family: $font-family-number;
		    .details{
				&__deductibles{
					color: $brand-color-1;
    				padding-left: math.div($base-padding, 6);
		    	}
			}
			.option-name {
				height: 1.6rem;
    			display: inline-block;
    			width: 100%;
			}
		}
		&_rate{
			font-family: $font-family-number;
    		width: 30%;
    		display: inline-block;
    		text-align: right;
		    .options-adhoc {
				display: inline-block;
    			text-align: center;
			}
		}
	}
	.options-button {
      @include make-one-fifth;
      padding-left: math.div($base-padding, 3);
      padding-right: 0;
	  float: right;
      &__book {
          @include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
          @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
          width: 100%;
          font-weight: 700;
          text-transform: uppercase;
      }
	}
	.options-highlights, .options-cancellation-policy {
		display: inline-block;
		width: 100%;
		color: $brand-color-2;
		cursor: pointer;
	}
	.options-cancellation-policy {
		padding-right: 0.6rem;
	}
}
