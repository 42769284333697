@use "sass:math";

.required-field-details-container {
	width: 100%;
	@include clearfix();
	float: left;
	padding-bottom: 1rem;

	&__error-container {
		width: 70%;
		padding: .625rem;
		padding-left: 0;
	}

	&__field-title {
		font-size: 1rem;
		font-weight: 700;
		color: $brand-color-2;
		padding: 0 .625rem;
		padding-left: 0;
		text-transform: uppercase;
	}

	&__note-info {
		font-size: 1rem;
		font-weight: 400;
		padding: 0 .625rem;
		padding-left: 0;
		color: $brand-color-5;

	}

	&__form-submit-button-container,
	&__book-button-container {
		@include make-one-fifth;
		padding-left: math.div($base-padding, 3);
		padding-right: 0;
		float: right;
		

		&_save,
		&_select-more,
		&_book {
			@include button-size(math.div($base-padding, 6), 0, $font-size-h5, inherit, 0);
			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state, 20));
			float: right;
			width: 300px;
			font-weight: 700;
			text-transform: uppercase;
		}
		&_select-more {
			margin-right: .625rem;
		}
		&_save {
			@include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20))
		}
	}

	&__book-button-container {
		position: absolute;
		bottom: -30px;
		right: 25px;
		width: 100%;
		@include clearfix();
	}

	&__field-container {
		padding: .625rem;
		padding-left: 0;
		width: 100%;
		@include clearfix();

		input[type="text"],
		input[type="number"],
		select {
			width: 100%;
			padding: 0.3rem 1rem;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			border: 1px solid;
			border-color: $input-border-color;
			height: 34px;
		}

		input[type="text"],
		input[type="number"] {
			background-color: $input-bgcolor;
			border: 1px solid;
			border-color: $input-border-color;
			max-height: 34px;
			position: relative;
		}

		&_date-picker {
			width: 24%;
			float: left;

		}

		&_image-container {
			width: 450px;
			height: 450px;
			>img{
				width: 450px;
			    height: 450px;
			}
			
		}

	}
}

.required-field-details-full-container {
	width: 100%;
}


.loader-container {
	display: flex;
	justify-content: left;
	width: 100%;
	padding-right: .625rem;
	padding-left: 0;
	padding-top: 0.625rem;
}


.sightseeing-placeholder {
	display: flex;
	flex-direction: column;
	width: 24%;
	border: 1px solid $brand-color-34;
	border-radius: 5px;
}

.block-placeholder-loader {
	padding: .625rem;
}

.multi-option-container {
	width: 100%;
	@include clearfix();

	&__title {
		float: left;
		font-size: 1rem;
		color: $brand-color-2;
		padding: .625rem;
		padding-left: 0;
		text-transform: uppercase;
		width: 30%;
		padding-top: .9475rem;

		&_desc {
			font-size: .75rem;
			color: $brand-color-18;
			font-weight: 600;
		}
	}

	&__selector {
		float: left;
		padding: .625rem;
		width: 30%;
	}
	&__info-icon {
		font-size: 1rem;
		float: left;
		color: $brand-color-2;
		padding: 15px;
		padding-left: 0;
	}

	&__price-container {
		float: left;
		text-align: left;

		&_price {
			font-size: .9375rem;
			font-family: $font-family-number;
			color: $brand-color-1;
		}

		&_currency {
			font-size: .75rem;
			color: $brand-color-18;
		}

		&_message {
			font-size: .75rem;
			color: $brand-color-18;
			padding-right: .3125rem;
			text-transform: capitalize;
		}

	}
}

.radio-option-container {
	position: relative;
	width: 70%;
	border: 1px solid;
	padding: .625rem;
	border-radius: 16px;
	margin-bottom: 1rem;
	@include clearfix;

	&__option-information,
	&__radio-button,
	&__price-container {
		float: left;
	}

	&__radio-button {
		padding: .5rem 0;
		width: 5%;
	}

	&__option-information {
		@include clearfix;
		width: 70%;

		&_details {
			font-size: 1rem;
			color: $brand-color-32;
			text-transform: uppercase;
		}

		&_description {
			font-size: .9375rem;
		}
	}

	&__price-container {
		float: left;
		width: 25%;
		text-align: right;

		&_price {
			font-size: $font-size-h3;
			font-family: $font-family-number;
			color: $brand-color-1;
		}

		&_currency {
			font-size: .75rem;
		}

		&_message {
			padding-right: .625rem;
		}

	}
}

.required-filed-option-selection-container {
	padding-top: .625rem;
	height: calc(100vh - 170px);
	overflow-y: scroll;
    padding-left: .625rem;
	&__title {
		font-size: 1.2rem;
		font-weight: 400;
		padding: .625rem;
		color: $brand-color-5;
		padding-left: 0;
		font-weight: 600;
	}

	&__price-container {
		width: 96%;
		text-align: right;
		position: fixed;
		padding: .625rem;
		top: 7.6rem;
		background: $light;
		padding-right: 0;
	}

	&__error-message {
		width: 70%;
		padding: .625rem;
		padding-top: 1rem;
	}

	&__loader-container {
		position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		&_loader-message {
			font-size: 1rem;
			color: $brand-color-2;
			text-align: center;
			padding-top: .625rem;
		}
	}

	&__cancellation-policy {
		float: left;
		width: 100%;
		margin-top: -1rem;

		&_title {
			font-size: 1rem;
			font-weight: 700;
			color: $brand-color-2;
			padding: 0 .625rem;
			padding-left: 0;
			text-transform: uppercase;
		}

		&_content {
			font-size: 0.875rem;
		}
		&_error-message{
			color:$brand-color-1;
			font-size: 1rem;
		}
	}
}

.patch-loader-container {
	padding: .625rem;
	padding-left: 0;
	display: flex;
	align-items: center;
    @include clearfix();
	&__loader {
		float: left;
		width: 25px;
		height: 25px;
		border: 2px solid $brand-color-39;
		border-top: 2px solid $brand-color-2;
		border-radius: 50%;
		animation: spin 1s linear infinite;
		margin-right: .5rem;
	}

		&__message {
			float: left;
			color: $brand-color-18;
			font-size: $font-size-h4;
			padding-left: .3125rem;
		}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}