@use "sass:math";

.sightseeing-contact__header,.sightseeing-desc__header,.sightseeing-amenity__header{
	min-height:1.875rem;
	background-color:$sightseeing-contact-header-bg-color;
	color:$sightseeing-contact-header-text-color;
	text-transform: uppercase;
	padding: 0.3125rem;
}

.sightseeing-contact__content{
	ul{
		padding-top:0.625rem;
		>li {
			padding-top:3px;
			border-bottom: 2px solid $sightseeing-contact-content-bottom-border-color;
			&:last-child{	
				border-bottom: none;
			}
		}
	}
}

.sightseeing-contact__content,.sightseeing-desc__content,.sightseeing-amenity__content{
	padding-left: 0.75rem;
	padding-bottom: 0.3125rem;
	&_sightseeing-name{
		color: $sightseeing-contact-content-title-text-color;
		font-size: 0.75rem;
	}
	&_title{
		color: $sightseeing-contact-content-title-text-color;
		font-size: 0.75rem;
		text-transform: uppercase;
	}
	&_info{
		color:$sightseeing-contact-content-info-text-color;
		padding-bottom: 2px;
		
		.sightseeing-contact{
			&__details{
				font-weight: bold;
				width:100%;
				padding-left: 0;
				font-size: 0.8rem;
			}
			
			&__icon{
				font-size: 0.5rem;
			}	
		}
	}
	&_city{
		color:$sightseeing-contact-content-info-text-color;
	}
}

.sightseeing-desc__content{
	padding-top:0.625rem;
}

.sightseeing-amenity__content{
	@include clearfix;
	&_amenity{
		min-width: 25%;
		float: left;
	}
}

.amenity__logo{
	padding-left: 0.9375rem;
	padding-right: 0.3125rem;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
}

.amenity__info{
	padding-right: 0.3125rem;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
}

.sightseeing-highlights-loader{
  	text-align: center;
}

.sightseeing-highlights {
  height: 600px;
  width: 100%;
  content: '';
  display: table;
  .highlights {
    &__sidebar {
      float: left;
      width: 25%;
      height: 100%;
      background-color: $body-bg;
    }
    &__sightseeing-details {
      float: right;
      width: 75%;
      height: 100%;
      margin-top: 0.625rem;
    }
}
 
.sidebar {
    &__back-button {
      margin: 0.25rem;
      background-color: white;
      border: 0.3125rem solid $brand-color-2;
      padding: 1.5625rem;
      button {
        cursor: pointer;
        padding-top: math.div($base-padding, 6);
        padding-bottom: math.div($base-padding, 6);
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        background-color: $brand-color-1;
        color: $light;
        display: block;
        &:hover {
          background-color: darken($brand-color-1, 20);
        }
      }
    }
    &__tabs {
      width: 100%;
      height: 80%;
      padding-bottom: 0.625rem;
      >li {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        text-align: left;
        position: relative;
        >a {
          color: $brand-color-2;
          padding-top: .9375rem;
          padding-bottom: .9375rem;
          display: block;
          text-decoration: none;
          cursor: pointer;
        }
        &:hover {
          background-color: $brand-color-2;
          a {
            color: $light;
          }
        }
        &:after {
          content: "";
          width: 80%;
          margin: 0 auto;
          height: 1px;
          background-color: white;
          position: absolute;
          top: 100%;
        }
      }
      .active {
        background-color: $light;
        a {
          color: $brand-color-1;
        }
      }
    }
}
  
.sightseeing-details {
    &__sightseeing-info {
      @include clearfix;
      width: 100%;
    }
    &__content {
      width: 100%;
      height: 80%;
      padding-left: .9375rem;
       .modal__content_sightseeing-desc, .modal__content_sightseeing-selection{
        	margin-top:1rem;
      }
    }
}

.sightseeing-info {
    &__sightseeing-image {
      float: left;
      padding-left: .9375rem;
      width: 20%;
      .result__thumbnail {
        width: 100%;
        height: 90px;
      }
    }
    &__sightseeing-description {
      float: left;
      width: 80%;
    }
    &__book {
	    padding-left: 1rem;
		padding-right: 1rem;
		>hr{
			border: 0.1rem solid $gray;
		}
      &_button {
        @include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
        @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
        float: right;
      	width: 20%;
      }
      .price{
		&__label{
			font-size: $font-size-h3;
	        font-family: $font-family-number;
		    color: $brand-color-2;
		}&__amount {
			font-size: $font-size-h3;
	        font-family: $font-family-number;
			color: $brand-color-1;
	    }
      }
    }
}

.description__sightseeing {
    padding-left: 10px;
    width: 100%;
    &_name {
      color: $brand-color-2;
      font-weight: 400;
      text-align: left;
      font-size: 1.125rem;
    }
}
  
.sightseeing-slideshow__header {
    min-height: 1.875rem;
    background-color: $sightseeing-contact-header-bg-color;
    color: $sightseeing-contact-header-text-color;
    text-transform: uppercase;
    padding-left: 0.3125rem;
    padding-top: 0.3125rem;
    padding-right: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-bottom: 0.625rem;
  }
}

 
 .sightseeing-details-modal-container {
	@include clearfix();
	width: 100%;

	&__close-button {
		width: 25%;
		float: right;
		text-align: right;
		cursor: pointer;
		font-size: 1.125rem;
		padding-bottom: $base-padding*0.25;
		color: $brand-color-2;
		text-transform: uppercase;

		&_icon {
			padding-right: .625rem;
		}
	}

	.modal__content {
		max-height: 100% !important;
	}

	&__sightseeing-highlight-loader {
		margin-top: 20%;
		text-align: center;
		font-size: 1rem;
		color: $brand-color-2;
		&_message{
			margin-top: 1rem;
		}
	}
	
	.sightseeing-slideshow {
	background-color: $light;
	width: 100%;
	@include clearfix();

	&__image-list {
		float: left;
		width: 20%;
		@include clearfix();
	}

	&__selected-image {
		float: left;
		width: 80%;
		padding-top: 3rem;
		@include clearfix();

		&_active-image {
			float: left;
			width: 90%;

			>img {
				width: 80%;
				height: calc(100vh - 180px);
				display: block;
				margin: 0 auto;
			}
		}

		&_slideshow-arrow-prev,
		&_slideshow-arrow-next {
			float: left;
			font-size: 4rem;
			margin-top: 18%;
		}
	}

}
}

.required-field-sightseeing-highlights {
	max-height: calc(100vh - 100px);
	width: 100%;
	overflow: auto;
	transition: all .5s ease-in-out;
	@include clearfix();

	&__sightseeing-name {
		padding-bottom: .625rem;
		font-size: 1.5rem;
		padding-left: .625rem;
		color: $brand-color-2;
	}
	
	.required-field-highlights {
		&__sidebar {
			float: left;
			width: 100%;
			height: 3.125rem;
			background-color: $body-bg;
		}

		&__sightseeing-details {
			float: right;
			width: 100%;
			height: 100%;
			margin-top: .625rem;
		}
	}

.sightseeing-header-description__sightseeing {
		padding-left: 10px;
		width: 100%;

		&_name {
			color: $brand-color-2;
			font-weight: 400;
			text-align: left;
			font-size: $font-size-h3;
		}

		&_duration {
			width: 100%;
			@include clearfix();

			&-title {
				float: left;
				font-weight: 600;
				color: $dark;
			}

			&-value {
				float: left;
				color: $brand-color-5;
				font-size: .75rem;
				font-weight: 600;
				padding-left: .3125rem;
			}
		}
	}

}

.vertical-sidebar {
		float: left;
		padding-left: 0;
		padding-right: 2rem;
		color: $light;
		text-transform: uppercase;
		position: fixed;
		width: 100%;

		&__sight-seeing-name {
			background-color: $brand-color-2;
			@include clearfix;
			width: 45%;
			padding-bottom: 0.5rem;
			display: flex;
			float: left;
			font-weight: 600;
			text-align: left;
			font-size: $font-size-h2;
			padding-top: 0.5rem;
			padding-left: 0.625rem;

			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&__tabs {
			background-color: $brand-color-2;
			@include clearfix;
			width: 55%;
			padding-bottom: 0.66rem;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			flex-direction: row;
			flex-wrap: nowrap;
			float: right;
			padding-top: 0.4375rem;

			&_action-button {
				text-align: center;
				@include make-one-fourth;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				min-height: 1.85rem;
				margin: 2px;
				font-size: 0.85rem;
				background-color: $light;
				border-radius: 0.1875rem;
				color: $brand-color-2;
			}

			.active {
				color: $brand-color-1;
			}
		}
	}
	
.required-field-sightseeing-details {
	&__sightseeing-info {
		@include clearfix;
		width: 100%;
		padding-bottom: .625rem;
	}

	&__content {
		width: 100%;
		height: 100%;

		&_active {
			opacity: 1 !important;
			transition: all ease-in-out 0.6s !important;
		}

		&_details {
			transition: all 0.5s ease;
			right: 0;
			top: 0;
			bottom: 0;
			opacity: 0;
		}

		.modal__content_sightseeing-desc,
		.modal__content_sightseeing-selection {
			margin-top: 1rem;
		}
	}
}

.sightseeing-description-details-container,
.sightseeing-inclusion-exclusion-container,
.sightseeing-address-container,
.sightseeing-slideshow-container,
.sightseeing-reviews-container,
 .sightseeing-select-option-container {
	padding-top: 3rem;
}

.required-field-sightseeing-contact__header,
.required-field-sightseeing-desc__header,
.required-field-sightseeing-amenity__header {
	min-height: 28px;
	background-color: $brand-color-3;
	color: $sightseeing-contact-header-text-color;
	text-transform: uppercase;
	padding: 5px;
	font-size: 0.75rem;
	font-weight: 600;
	padding-left:.625rem ;
}

.required-field-sightseeing-contact__content {
	ul {
		padding-bottom: .625rem;

		>li {
			padding-top: .3125rem;
			border-bottom: 2px solid $sightseeing-contact-content-bottom-border-color;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.required-field-sightseeing-contact__content,
.required-field-sightseeing-desc__content,
.required-field-sightseeing-amenity__content {
	padding-left: 12px;

	&_sightseeing-name {
		color: $sightseeing-contact-content-title-text-color;
		font-size: .75rem;
	}

	&_title {
		color: $sightseeing-contact-content-title-text-color;
		font-size: .75rem;
		text-transform: uppercase;
	}

	&_info {
		color: $sightseeing-contact-content-info-text-color;

		.sightseeing-contact {
			&__details {
				font-weight: bold;
				width: 100%;
				padding-left: 0;
				font-size: 0.8rem;
			}

			&__icon {
				font-size: 0.5rem;
			}
		}
	}

	&_location {
		width: 100%;
	
	}

	&_city {
		color: $sightseeing-contact-content-info-text-color;
	}
}

.required-field-sightseeing-desc__content {
	padding-top: .625rem;
}

.required-field-sightseeing-options {
	@include clearfix;
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 10px;
	padding-left: math.div($base-padding, 3);
	padding-right: math.div($base-padding, 3);
	padding-bottom: math.div($base-padding, 3);
	background-color: $light;
	border: 0.625rem solid $brand-color-3;

	&__language,
	&__pickup,
	&__date,
	&__tour-time,
	&__essential-notes,
	&__question-answer,
	&__price,
	&__segmentation-groups {
		&_header {
			min-height: 1.875rem;
			background-color: $brand-color-3;
			color: $brand-color-5;
			text-transform: uppercase;
			padding: 0.3125rem;
			padding-left: .625rem;
		}

		&_err-msg {
			text-align: justify;
			padding-top: 1rem;
			padding-left: 1rem;
			color: $brand-color-1;
		}

		&_content {
			padding-left: .625rem;
			text-align: justify;
			padding-bottom: .625rem;
			padding-top: .625rem;

			.segment-header {
				color: $brand-color-2;
			}

			.segments-name__icon {
				font-size: 0.5rem;
			}
		}

		&_data {
			margin-top: .3125rem;
			margin-bottom: .625rem;
			margin-left: .625rem;

			>select {
				width: 63%;
				padding: 0.5rem 1rem;
				background: none;
				vertical-align: middle;
				line-height: normal;
			}

			>label {
				padding-left: 1rem;
			}
		}

		&_marhaba-content {
			padding: 0.5rem 1rem;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			width: 25%;
		}
	}

	&__info-message {
		padding-left: 1rem;
		text-align: justify;
		padding-top: 1rem;
		color: $brand-color-1;
	}

	&__pickup,
	&__language,
	&__tour-time,
	&__essential-notes,
	&__price,
	&__segmentation-groups {
		width: 100%;
	}
}

.required-field-sightseeing-info {
	&__feature-info-container {
		width: 100%;
		@include clearfix();

		&_feature {
			float: left;
			background: $brand-color-7;
			color: $dark;
			border-radius: 3px;
			padding: 0.3125rem;
			margin-left: 0.625rem;
			margin-bottom: 0.625rem;
			font-weight: 400;
		}
	}

	&__sightseeing-image {
		float: left;
		padding-left: .625rem;
		width: 20%;

		.result__thumbnail {
			width: 100%;
			height: 150px;
		}
	}

	&__sightseeing-description {
		float: left;
		width: 60%;
	}

	&__price-container {
		float: left;
		width: 40%;

		&_title {
			font-weight: 400;
			text-align: left;
			font-size: 1.125rem;
			color: $brand-color-2;
			padding-right: .625rem;
		}

		&_price {
			text-align: left;

			&-currency {
				font-size: .75rem;
			}

			&-value {
				font-size: $font-size-h3;
				font-family: $font-family-number;
				color: $brand-color-1;
			}

			&-adhoc-price {
				color: $brand-color-14;
			}

			&-adhoc {
				display: inline-block;
				text-align: center;
			}
		}
	}

	&__select-option-button-container {
		width: 100%;
		padding: .9375rem 0;
		@include clearfix();

		&_button {
			@include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state, 20));
			width: 100%;
			font-weight: 700;
			text-transform: uppercase;
			float: right;
			width: 15%;
		}
	}

	&__book {
		padding-left: 1rem;
		padding-right: 1rem;

		>hr {
			border: 0.1rem solid $gray;
		}

		&_button {
			@include button-size(math.div($base-padding, 6), 0, $font-size-h6, inherit, 0);
			@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state, 20));
			width: 100%;
			font-weight: 700;
			text-transform: uppercase;
			float: right;
			width: 20%;
		}

		.price {
			&__label {
				font-size: $font-size-h3;
				font-family: $font-family-number;
				color: $brand-color-2;
			}

			&__amount {
				font-size: $font-size-h3;
				font-family: $font-family-number;
				color: $brand-color-1;
			}
		}
	}

}